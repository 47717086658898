export const GREEN = {
  main: '#798473',
  light: '#a1a99d',
  dark: '#242822',
};
export const GREY = {
  main: '#7A7176',
  light: '#a29c9f',
  dark: '#252223',
};
export const PINK = {
  main: '#C6809B',
  light: '#eed9e1',
  dark: '#d7a6b9',
};
export const RED = {
  main: '#E05C61',
  light: '#e98d90',
  dark: '#431c1d',
};
export const ORANGE = {
  main: '#D18025',
  light: '#dfa666',
  dark: '#3f260b',
};
export const BLUE = {
  main: '#90CAF9',
  light: '#b1dafb',
  dark: '#2b3d4b',
};
export const BACKGROUND = '#79847321';

export const PALETTE = {
  primary: GREEN,
  secondary: GREY,
  error: RED,
  info: PINK,
  warning: ORANGE,
  success: BLUE,
};
