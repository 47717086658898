type Props = {
  color?: string;
  textAlign?: 'left' | 'center' | 'right';
  fontWeight?: string;
  fontSize?: string;
  fancy?: boolean;
  disabled?: boolean;
  style?: any;
	onClick?: (e: any) => void;
};

export const Text: React.FC<React.PropsWithChildren<Props>> = ({
  color,
  textAlign,
  fontWeight,
  fontSize,
  fancy,
  disabled,
  style,
  children,
	onClick,
}) => {
  return (
    <p
      style={{
        color: color || (disabled ? 'lightgrey' : 'black'),
        textAlign: textAlign || 'left',
        fontWeight: fontWeight || 'normal',
        fontSize: fontSize || '16px',
        fontFamily: fancy ? 'cursive' : 'Arial',
        fontStyle: fancy ? 'italic' : 'normal',
        padding: 0,
        margin: 0,
				cursor: onClick ? 'pointer' : 'default',
        wordWrap: 'break-word',
        ...style,
      }}
			onClick={onClick}
    >
      {children}
    </p>
  );
};
