import { Box } from "@mui/material";
import { styled } from "styled-components";
import { PALETTE } from "../theme/palette";

type Props = {
    elevation: number;
}
export const Paper = styled(Box)<Props>`
    background-color: white;
    border-radius: 3px;
    box-shadow: ${({ elevation }) => `1px 1px ${elevation * 3}px ${elevation}px ${PALETTE.primary.main}`};
`;
