import { useMutation } from 'react-query';
import { TStandardForm } from '../shared/standards';
import { updateUser } from '../api/users';
import { TVendorForm } from '../shared/vendors';

type UserUpdateOptions = {
  id: string;
  data: TStandardForm | TVendorForm;
};
export const useUpdateUserMutation = () => {
  return useMutation({
    mutationFn: ({ id, data }: UserUpdateOptions) => updateUser(id, data),
  });
};
