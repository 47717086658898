import { Page } from '../../../components/layout/page';
import { Blocked } from '../profile/blocked';
import { Stack, Typography } from '@mui/material';
import { LoadingText } from '../../../components/Loading';
import { useAuth } from '../../../auth/useAuth';
import { VendorForm } from '../../../components/forms/VendorForm';
import { useUpdateVendor } from './profilePageHooks';
import { Link } from 'react-router-dom';

export const VendorProfilePage = () => {
  const { user } = useAuth();
  const { updateVendor, error } = useUpdateVendor();

  if (!user) return <LoadingText />;
  if (user.subscription !== 'vendor') return null;

  if (user.blocked) {
    return <Blocked />;
  }

  return (
    <Page header="PROFILE SETTINGS" maxWidth="400px">
      <Stack spacing={'2rem'} alignItems={'center'}>
        <Link to={`/vendors/${user._id}`}>View Profile</Link>
        <VendorForm
          submitLabel={'Update Profile'}
          initialValues={user}
          onSubmit={(form, setSubmitting) => updateVendor(user, form, setSubmitting)}
        />
      </Stack>
    </Page>
  );
};
