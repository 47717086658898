import { Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material';
import TextField from './TextField';
import React from 'react';
import { Price } from '../../shared/misc';
import { CustomEvent } from './WeekdayHoursPicker';

type Props = {
  id: string;
  name: string;
  value?: Price;
  required?: boolean;
  onChange: (e: CustomEvent) => void;
};
const PriceRangePicker = ({ id, name, value, required, onChange }: Props) => {
  const initialMin = value?.type !== 'Free' ? value?.min : undefined;
  const initialMax = value?.type === 'Range' ? value.max : undefined;

  const [isFree, setIsFree] = React.useState<boolean>(value?.type === 'Free');
  const [isRange, setIsRange] = React.useState<boolean>(value?.type === 'Range');
  const [min, setMin] = React.useState<number | undefined>(initialMin);
  const [max, setMax] = React.useState<number | undefined>(initialMax);

  React.useEffect(() => {
    if (isFree) {
      onChange({
        type: 'change',
        target: {
          id,
          name,
          value: { type: 'Free' },
        },
      });
    } else if (isRange) {
      onChange({
        type: 'change',
        target: {
          id,
          name,
          value: { type: 'Range', min, max },
        },
      });
    } else {
      onChange({
        type: 'change',
        target: {
          id,
          name,
          value: { type: 'Single', min },
        },
      });
    }
  }, [isFree, isRange, min, max]);

  return (
    <FormGroup>
      <Stack direction={'row'} spacing={2}>
        <TextField
          required={required}
          disabled={isFree}
          type="number"
          value={min}
          label={isRange ? 'PRICE MIN' : 'PRICE'}
          onChange={setMin}
          // startAdornment={<InputAdornment position="start">$</InputAdornment>}
        />
        {isRange && (
          <TextField
            required={required}
            disabled={isFree}
            type="number"
            value={max}
            label={isRange ? 'PRICE MAX' : 'PRICE'}
            onChange={setMax}
            // startAdornment={<InputAdornment position="start">$</InputAdornment>}
          />
        )}
      </Stack>
      <Stack direction={'row'} spacing={2}>
        <FormControlLabel
          label="Free"
          control={
            <Checkbox
              color="primary"
              checked={isFree}
              onChange={(value) => {
                setIsFree(value.target.checked);
              }}
            />
          }
        />
        <FormControlLabel
          label="Price Range"
          control={
            <Checkbox
              color="primary"
              checked={isRange}
              onChange={(value) => {
                setIsRange(value.target.checked);
              }}
            />
          }
        />
      </Stack>
    </FormGroup>
  );
};

export default PriceRangePicker;
