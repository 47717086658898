import React from 'react';
import { DeleteForeverOutlinedIcon } from '.';
import Icon from './Icon';
import { useTheme } from '@mui/material';

export type Props = {
  fontSize?: 'small' | 'large';
  onClick: () => void;
  disabled?: boolean;
};
export const Delete: React.FC<Props> = ({ onClick, disabled, fontSize }) => {
  const theme = useTheme();
  const color = theme.palette.error.main;
  const displayColor = disabled ? 'lightgrey' : color;
  return (
    <Icon tooltip="Delete" onClick={onClick}>
      <DeleteForeverOutlinedIcon style={{ color: displayColor }} fontSize={fontSize || "large"} />
    </Icon>
  );
};
