import { Divider, Grid, ListItem, Stack } from '@mui/material';
import { Text } from '../text';
import { DATE_SHORT, TIME } from '../../formatting';
import { formatAddress, formatPrice } from '../../utils/formatting';
import { Message } from '../icons/Message';
import { Paper } from '../Paper';
import React from 'react';
import { getReccurringLabel } from '../../utils/days';
import { useAuth } from '../../auth/useAuth';
import { TEvent } from '../../shared/events';
import { DateTimeRepeat } from '../inputs/EventDateTimePicker/types';
import { GridImageItem } from '../Image';
import { ClickableGrid } from './ClickableGrid';
import { ActionButtons } from '../ActionButtons';
import { useActionButtons } from '../../hooks/useActionButtons';
import { usePublicUser } from '../../context/PublicUserProvider';

// TODO - Follow this ActionButtons pattern with VendorItem and VendorPage

type DateItemProps = {
  dateTime: DateTimeRepeat;
};
const GridDateItem: React.FC<DateItemProps> = ({ dateTime }) => {
  const reoccurringLabel = getReccurringLabel(dateTime);
  return (
    <Grid item xs={3} padding={'1rem'}>
      <Stack justifyContent={'center'} alignItems={'center'} height={'100%'}>
        <Text fontSize="24px" fontWeight="bolder" textAlign="center">
          {new Date(dateTime.start).toLocaleDateString(undefined, DATE_SHORT as any)}
        </Text>
        {dateTime.type !== 'Does not repeat' && (
          <Text textAlign="center" fontSize="16px">
            {reoccurringLabel}
          </Text>
        )}
        <br />
        <Divider flexItem />
        <br />
        <Text fontSize="24px" fontWeight="bolder" textAlign="center">
          {new Date(dateTime.start).toLocaleTimeString(undefined, TIME as any)}
        </Text>
      </Stack>
    </Grid>
  );
};

type InfoItemProps = {
  event: TEvent;
};
const GridInfoItem: React.FC<InfoItemProps> = ({ event }) => {
  return (
    <Grid item xs={6} padding={'1rem'}>
      <Stack spacing={'1rem'} justifyContent={'center'} height={'100%'}>
        <Text fontWeight="bold" fontSize="24px">
          {event.name.toUpperCase()}
        </Text>
        <Text fontSize="20px">{event.vendorName}</Text>
        <br />
        {event.address.venue && (
          <Text fontWeight="bold" fontSize="18px">
            {event.address.venue}
          </Text>
        )}
        <Text>{formatAddress(event.address)}</Text>
        <br />
        {event.price && <Text>Price: {formatPrice(event.price)}</Text>}
      </Stack>
    </Grid>
  );
};

type Props = {
  event: TEvent;
  onError: (error: unknown) => void;
  onClick: (event: TEvent) => void;
};
export const EventItem: React.FC<Props> = (props) => {
  const { event, onClick, onError } = props;
  const { user } = useAuth();
  const { publicIsLikedEvent } = usePublicUser();

  const {
    likeToggleMutation,
    calendarToggleMutation,
    toggleLikeClick,
    toggleCalendarClick,
    onMessagesClick,
  } = useActionButtons();

  const { error: likeEventError, isLoading: isLoadingLike } = likeToggleMutation;
  const { error: calendarError, isLoading: isLoadingCalendar } = calendarToggleMutation;

  // TODO - NEW
  const isOwner = user && user._id === event._id;
  const isPublicUser = !user;
  const isStandardUser = user && user.subscription === 'standard';
  const isVendorUser = user && user.subscription === 'vendor';
  const isAdminUser = user && user.subscription === 'admin';

  // TODO: Handle isLiked for Other Types of Users Besides Public
  const isOnCalendar = false;
  const isLiked = isPublicUser ? publicIsLikedEvent(event._id) : false;

  const onLikeClick = () => toggleLikeClick(isLiked, event._id);
  const onCalendarClick = () => toggleCalendarClick(isOnCalendar, event._id);
  const onMessage = () => onMessagesClick(event._id);

  const error = likeEventError || calendarError;

  React.useEffect(() => {
    if (error) onError(error);
  }, [error]);

  return (
    <ListItem disabled={event.blocked}>
      <Paper elevation={1} width={'100%'}>
        <ClickableGrid container padding={'1rem 0'} onClick={() => onClick(event)}>
          <Grid container item xs={11} borderRight={'1px solid lightgrey'}>
            <GridDateItem dateTime={event.dateTime} />
            <GridImageItem src={event.photo} alt={event.name} />
            <GridInfoItem event={event} />
          </Grid>
          <Grid item xs={1}>
            <ActionButtons
              isLoadingLike={isLoadingLike}
              isLoadingCalendar={isLoadingCalendar}
              isLiked={isLiked}
              isOnCalendar={isOnCalendar}
              onLikeClick={isPublicUser || isStandardUser ? onLikeClick : undefined}
              onCalendarClick={isStandardUser ? onCalendarClick : undefined}
              onMessageClick={
                (isStandardUser && isOnCalendar) || (isVendorUser && isOwner) || isAdminUser
                  ? onMessage
                  : undefined
              }
            />
          </Grid>
          {event.blocked && (
            <Grid
              container
              item
              xs={12}
              justifyContent={'center'}
              padding={'1rem'}
              marginTop={'1rem'}
              style={{ background: '#0000001c' }}
            >
              <Text textAlign="center">Event is blocked</Text>
            </Grid>
          )}
        </ClickableGrid>
      </Paper>
    </ListItem>
  );
};
