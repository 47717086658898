import { Stack } from '@mui/material';
import { Text } from '../text';
import React from 'react';
import WeekdayPicker from './WeekdayPicker';
import TimePicker from './TimePicker';
import dayjs from 'dayjs';
import { Day } from '../Day';
import { DAY_VALUES, DayType } from '../../shared/misc';

export type DayHours = {
  day: DayType;
  hours: Hours;
};

type Hours = {
  open: number;
  close: number;
};

export type HoursSpan = {
  from: number;
  to: number;
};
export type DaysOpenMap = {
  [day: string]: HoursSpan;
};

type Props = {
  id?: string;
  name?: string;
  value: DayHours[];
  onChange: (event: CustomEvent) => void;
};
export type CustomEvent = {
  type: string;
  target: {
    id: string;
    name: string;
    value: any;
  };
};
export const WeekdayHoursPicker: React.FC<Props> = ({ id, name, value, onChange }) => {
  const [daysOpen, setDaysOpen] = React.useState<DayHours[]>(value || []);
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    onChange({
      type: 'change',
      target: {
        id: id || 'id',
        name: name || 'name',
        value: daysOpen,
      },
    });
  }, [daysOpen]);

  const handleDaySelected = (days: string[]) => {
    const ds = days
      .map<DayHours>((day) => {
        const foundDay = daysOpen.filter((cDay) => {
          return cDay.day === day;
        })[0];
        return {
          day,
          hours: foundDay
            ? foundDay.hours
            : { open: new Date().getTime(), close: new Date().getTime() },
        };
      })
      .sort((a, b) => {
        const aValue = DAY_VALUES[a.day];
        const bValue = DAY_VALUES[b.day];
        return aValue - bValue;
      });
    setDaysOpen(ds);
  };

  const handleOpenHoursChange = (day: DayType, date: Date) => {
    const foundDay = daysOpen.filter((cDay) => {
      return cDay.day === day;
    })[0];
    if (foundDay) {
      const newDay = { ...foundDay };
      newDay.hours.open = date.getTime();
      const newDaysOpen = [...daysOpen];
      const index = newDaysOpen.findIndex((d) => d.day === day);
      if (index >= 0) {
        newDaysOpen[index] = newDay;
        setDaysOpen(newDaysOpen);
      }
    }
  };

  const handleCloseHoursChange = (day: DayType, date: Date) => {
    const foundDay = daysOpen.filter((cDay) => {
      return cDay.day === day;
    })[0];
    if (foundDay) {
      const newDay = { ...foundDay };
      newDay.hours.close = date.getTime();
      const newDaysOpen = [...daysOpen];
      const index = newDaysOpen.findIndex((d) => d.day === day);
      if (index >= 0) {
        newDaysOpen[index] = newDay;
        setDaysOpen(newDaysOpen);
      }
    }
  };

  return (
    <Stack ref={inputRef}>
      <Text textAlign="center">DAYS OF OPERATION</Text>
      <WeekdayPicker
        value={daysOpen.map(({ day }) => day as string)}
        onChange={handleDaySelected}
      />
      {daysOpen.length > 0 && (
        <Stack alignItems={'center'} spacing={'15px'}>
          {daysOpen.map(({ day, hours }) => {
            return (
              <React.Fragment key={day}>
                <br />
                <Stack key={day} alignItems={'center'} direction={'row'} spacing={'1rem'}>
                  <Day day={day as string} selected={true} onClick={() => {}} />
                  <TimePicker
                    label="Opening Time"
                    value={dayjs(new Date(hours.open))}
                    onChange={(d) => handleOpenHoursChange(day, d.toDate())}
                  />
                  <TimePicker
                    label="Closing Time"
                    value={dayjs(new Date(hours.close))}
                    onChange={(d) => handleCloseHoursChange(day, d.toDate())}
                  />
                </Stack>
              </React.Fragment>
            );
          })}
        </Stack>
      )}
    </Stack>
  );
};
