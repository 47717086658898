import { del, get, patch, post } from '../utils/networking';
import { ClaimForm, VendorStatistics } from '../types/users/vendor';
import { getBaseUrl } from '../utils/api';
import { TVendor, TVendorForm } from '../shared/vendors';
import { TEvent, TEventForm } from '../shared/events';

export type VendorWithHandoff = {
  vendor: TVendor;
  code: string;
};

const baseUrl = getBaseUrl();
const url = `${baseUrl}/vendors`;

export const useVendorsAPI = () => {
  // const { token } = useToken();

  const fetchVendors = async (): Promise<TVendor[]> => {
    const { data } = await get(`${url}`, undefined);
    return data as TVendor[];
  };
  const fetchCreateVendor = async (form: TVendorForm) => {
    const { data } = await post(`${url}`, form, undefined);
    return data as VendorWithHandoff;
  };
  const fetchVendor = async (id: string): Promise<TVendor> => {
    const { data } = await get(`${url}/${id}`, undefined);
    return data as TVendor;
  };
  const fetchUpdateVendor = async (id: string, form: TVendorForm) => {
    const { data } = await patch(`${url}/${id}`, undefined, form);
    return data;
  };
  const fetchCreateEventForVendor = async (vendorId: string, form: TEventForm): Promise<TEvent> => {
    const address = `${url}/${vendorId}/events`;
    const { data } = await post(address, form, undefined);
    return data as TEvent;
  };
  const fetchVendorEvents = async (vendorId: string): Promise<TEvent[]> => {
    const address = `${url}/${vendorId}/events`;
    const response = await get(address, undefined);
    return response.data;
  };
  const fetchBlockVendor = async (vendorId: string): Promise<void> => {
    const address = `${url}/${vendorId}/block`;
    await get(address, undefined);
  };
  const fetchUnblockVendor = async (vendorId: string): Promise<void> => {
    const address = `${url}/${vendorId}/block`;
    await del(address, undefined);
  };
  const fetchAcceptVendorTermsAndConditions = async (vendorId: string): Promise<void> => {
    const address = `${url}/${vendorId}/terms-and-conditions`;
    await get(address, undefined);
  };
  const fetchClaimVendorAccount = async (claimForm: ClaimForm) => {
    const address = `${url}/claim`;
    await post(address, claimForm);
  };
  const fetchVendorHandoffCode = async (vendorId: string) => {
    const address = `${url}/${vendorId}/handoff-code`;
    const { data } = await get(address, undefined);
    return data.code as string;
  };
  const fetchVendorStatistics = async (vendorId: string) => {
    const address = `${url}/${vendorId}/stats`;
    const { data } = await get(address, undefined);
    return data as VendorStatistics;
  };
  const fetchVendorViewed = async (vendorId: string) => {
    const address = `${url}/${vendorId}/stats/views`;
    await get(address, undefined);
  };
  const fetchVendorLiked = async (vendorId: string) => {
    const address = `${url}/${vendorId}/stats/likes`;
    await get(address, undefined);
  };

  return {
    fetchVendors,
    fetchCreateVendor,
    fetchVendor,
    fetchUpdateVendor,
    fetchCreateEventForVendor,
    fetchVendorEvents,
    fetchBlockVendor,
    fetchUnblockVendor,
    fetchAcceptVendorTermsAndConditions,
    fetchClaimVendorAccount,
    fetchVendorHandoffCode,
    fetchVendorStatistics,
    fetchVendorViewed,
    fetchVendorLiked,
  };
};
