import React from 'react';
import { CheckOutlinedIcon } from '.';
import Icon from './Icon';
import { useTheme } from '@mui/material';

export type Props = {
  fontSize?: 'small' | 'large';
  onClick?: () => void;
};
export const Checkmark: React.FC<Props> = ({ onClick, fontSize }) => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  return (
    <Icon tooltip={onClick && 'Done'} onClick={onClick}>
      <CheckOutlinedIcon style={{ color }} fontSize={fontSize || "large"} />
    </Icon>
  );
};
