import { useMutation } from 'react-query';
import { likeEvent } from '../api/standards';

type LikeEventOptions = {
  userId: string;
  eventId: string;
};
export const useLikeEventMutation = () => {
  return useMutation({
    mutationFn: ({ userId, eventId }: LikeEventOptions) => likeEvent(userId, eventId),
  });
};
