import { TypeOptions, toast } from 'react-toastify';

export const SIGN_IN_SUCCESS = 'Successfully signed in';
export const SIGN_OUT_SUCCESS = 'Successfully signed out';
export const REGISTER_SUCCESS = 'Successfully registered';
export const ADD_TO_CALENDAR_SUCCESS = 'Successfully added to calendar';
export const REMOVE_FROM_CALENDAR_SUCCESS = 'Successfully removed from calendar';
export const ADD_TO_LIKES_SUCCESS = 'Successfully added to likes';
export const REMOVE_FROM_LIKES_SUCCESS = 'Successfully removed from likes';
export const FOLLOW_VENDOR_SUCCESS = 'Successfully following vendor';
export const BLOCK_VENDOR_SUCCESS = 'Successfully blocked vendor';
export const UNBLOCK_VENDOR_SUCCESS = 'Successfully unblocked vendor';
export const REMOVE_FOLLOW_VENDOR_SUCCESS = 'Successfully unfollowed vendor';
export const CREATE_EVENT_SUCCESS = 'Successfully created event';
export const UPDATE_EVENT_SUCCESS = 'Successfully updated event';
export const CREATE_VENDOR_SUCCESS = 'Successfully created vendor';
export const UPDATE_PROFILE_SUCCESS = 'Successfully updated profile';
export const POST_MESSAGE_SUCCESS = 'Successfully sent message';
export const UPDATE_MESSAGE_SUCCESS = 'Successfully updated message';
export const DELETE_MESSAGE_SUCCESS = 'Successfully deleted message';
export const COPY_SUCCESS = 'Successfully copied to clipboard';
export const MUST_REGISTER_CALENDAR_WARNING = 'Must register to add event to calendar';

export const useToast = () => {
    const showToast = (message: string, type: TypeOptions) => {
        toast(message, { type });
    }
    return { showToast };
}
