export const COMPANY_MISSION =
  'Help people meet in real life, share common experiences, and build community.';
export const COMPANY_MISSION_PARAGRAPHS = [
  'Simply stated, our mission is to “help people meet in real life, share common experiences, and build community connections.” But there is so much more to it than that simple statement...',
  'We have been witnessing and experiencing the epidemic of depression and anxiety in our culture, trying to figure out how to help ourselves and hopefully also help others.',
  'We know from struggles in our own lives that stronger connections to the people around us reduce our negative feelings. Plus, it is well documented that humans are social creatures. We need to physically experience the presence of others to feel complete.',
  'We recognize how difficult it is to make new in-real-life connections in the age of social media and virtual connections. How to get out in the real world and make friends? Finding things to do can lead to internet searches filled with links to various community calendars, some with appealing events, some with events of absolutely no interest.',
  'So, we started to discuss possible solutions and an interesting evolution of ideas led us to develop this app “Social Life of Mine.” There continues to be hours of conversation as to how that app will be used, is it safe for the users, is it better than what is already available. We know this is a work-in-progress, and probably always will be, but we will continue to strive to meet our simple mission statement “Help people meet in real life, share common experiences, and build community connections.”',
  'As to how that app will be used, is it safe for the users, is it better than what is already available. We know this is a work-in-progress, and probably always will be, but we will continue to strive to meet our simple mission statement “Help people meet in real life, share common experiences, and build community connections.”',
];
