import { Grid } from '@mui/material';
import { styled } from 'styled-components';

export const ClickableGrid = styled(Grid)`
  * > {
    pointer-events: none;
  }

  &:hover {
    box-shadow: 3px 3px 7px 3px grey;
    cursor: pointer;
  }
`;
