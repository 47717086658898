import { useMutation, useQueryClient } from 'react-query';
import { unblockEventById } from '../api/events';

type UnblockEventOptions = {
  eventId: string;
};
export const useUnblockEventMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ eventId }: UnblockEventOptions) => unblockEventById(eventId),
    onSuccess: (event) => {
      queryClient.invalidateQueries({ queryKey: ['getEventById', event._id] });
    },
  });
};
