export const MINIMUM_AGE = 18;
export const MINIMUM_YEAR = 1900;
export const YEARS_TO_MILISECONDS = 31556952000;

export const isValidBirthday = (timestamp: number): boolean => {
  const is18 = Date.now() - timestamp > MINIMUM_AGE * YEARS_TO_MILISECONDS;
  const isInRange = new Date(timestamp).getFullYear() > MINIMUM_YEAR;
  return is18 && isInRange;
};

export const getNetworkErrorMessage = (err: any): string => {
  const defaultMessage = 'An error occurred, please try again.';
  if (!err.response || !err.response.data) return defaultMessage;
  const messages = err.response.data.message.split('|').map((message: string) => message.trim());
  if (!messages || messages.length <= 0) return defaultMessage;
  return messages[0];
};
