import { useMutation } from 'react-query';
import { removeEventFromCalendar } from '../api/standards';

type RemoveEventFromCalendarOptions = {
  userId: string;
  eventId: string;
};
export const useRemoveEventFromCalendarMutation = () => {
  return useMutation({
    mutationFn: ({ userId, eventId }: RemoveEventFromCalendarOptions) =>
      removeEventFromCalendar(userId, eventId),
  });
};
