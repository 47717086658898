import { TVendor, TVendorForm } from '../shared/vendors';
import { getVendorUser } from '../utils/userUtils';
import { createFetch, createQueryParams } from './createFetch';

export type GetVendorsOptions = {
  claimed?: boolean;
  unclaimed?: boolean;
  blocked?: boolean;
};
export const getVendors = async (options: GetVendorsOptions) => {
  const params = createQueryParams(options);
  const query = new URLSearchParams(params).toString();
  const result = await createFetch('/vendors?' + query, 'GET');
  return (await result.json()) as TVendor[];
};

// TODO - This only gets unclaimed vendors...
export const getVendorById = async (vendorId: string | undefined) => {
  const result = await createFetch(`/vendors/${vendorId}`, 'GET');
  return (await result.json()) as TVendor;
};

export const createVendor = async (data: TVendorForm, token?: string) => {
  const body = JSON.stringify(data);
  const result = await createFetch('/vendors', 'POST', body, token);
  return (await result.json()) as TVendor;
};

export const updateUnclaimedVendor = async (data: TVendorForm, vendor: TVendor, token?: string) => {
  const { _id, claimed } = vendor;
  const body = JSON.stringify(data);
  const url = claimed ? `/users/${_id}` : `/vendors/${_id}`;
  const response = await createFetch(url, 'PATCH', body, token);
  const result = await response.json();
  const newVendor = result.data;
  return newVendor as TVendor;
};
