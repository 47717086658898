import styled from 'styled-components';
import { Colors } from '../../theme/colors';
import { Link as LinkDOM } from 'react-router-dom';
import { PALETTE } from '../../theme/palette';

const FOOTER_SMALL = 600;

const Layout = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 3rem;
  padding: 2rem 3rem;
  background-color: ${Colors.primary};
  box-shadow: 0 0 5px 3px ${PALETTE.primary.main};

  @media (max-width: ${FOOTER_SMALL}px) {
    flex-direction: column;
    gap: 1rem;
    padding: 2rem;
  }
`;
export const Link = styled(LinkDOM)`
  color: white;
  font-size: 24px;
  text-decoration: none;
`;

const Footer = () => {
  return (
    <Layout>
      <Link to="/">ABOUT US</Link>
      <Link to="contact-us">CONTACT US</Link>
    </Layout>
  );
};

export default Footer;
