import { Page } from '../../../components/layout/page';
import { useParams } from 'react-router-dom';
import { LoadingText } from '../../../components/Loading';
import { useGetEventByIdQuery } from '../../../queries/useGetEventByIdQuery';
import { EventForm } from '../../../components/forms/EventForm';
import { useGetVendorByIdQuery } from '../../../queries/useGetVendorByIdQuery';
import { ErrorText } from '../../../components/text/ErrorText';
import { useUpdateEvent } from './updateEventHooks';

export const UpdateEventPage = () => {
  const { id } = useParams();
  const { isLoading: isLoadingEvent, data: event, error: eventError } = useGetEventByIdQuery(id);
  const {
    isLoading: isLoadingVendor,
    data: vendor,
    error: vendorError,
  } = useGetVendorByIdQuery(event?._id);
  const { updateEvent, error } = useUpdateEvent();

  if (isLoadingEvent || isLoadingVendor) return <LoadingText />;
  if (!event || !vendor) return <LoadingText />;
  if (eventError !== null || vendorError !== null)
    return <ErrorText>Something went wrong. Please try again.</ErrorText>;

  return (
    <Page header={event.name} maxWidth="400px">
      <EventForm
        submitLabel="Update Event"
        initialValues={event}
        onSubmit={(form, setSubmitting) => updateEvent(form, event._id, setSubmitting)}
        vendor={vendor}
      />
    </Page>
  );
};
