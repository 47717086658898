import { del, get, patch, post } from '../utils/networking';
import { getBaseUrl } from '../utils/api';
import { useVendorsAPI } from './useVendorsAPI';
import { useEventsAPI } from './useEventsAPI';
import { Notification } from '../types/notifications';
import { TStandard, TStandardForm } from '../shared/standards';

const baseUrl = getBaseUrl();

export const useStandardsAPI = () => {
  // const { token } = useToken();
  const { fetchVendorLiked } = useVendorsAPI();
  const { fetchEventLiked, fetchEventRsvpd } = useEventsAPI();

  const fetchStandards = async () => {
    const { data } = await get(`${baseUrl}/standards`);
    return data as TStandard[];
  };
  const fetchCreateStandard = async (form: TStandardForm) => {
    const { data } = await post(`${baseUrl}/standards`, form);
    return data as TStandard;
  };
  const fetchStandard = async (id: string): Promise<TStandard> => {
    const { data } = await get(`${baseUrl}/standards/${id}`, undefined);
    return data as TStandard;
  };
  const fetchUpdateStandard = async (id: string, form: TStandardForm) => {
    const { data } = await patch(`${baseUrl}/standards/${id}`, undefined, form);
    return data as TStandard;
  };
  const fetchAttendEvent = async (id: string, eventId: string) => {
    const { data } = await post(`${baseUrl}/standards/${id}/rsvps`, { eventId }, undefined);
    const rsvpEvents = data as string[];
    fetchEventRsvpd(eventId);
    return rsvpEvents;
  };
  const fetchUnattendEvent = async (id: string, eventId: string) => {
    const { data } = await del(`${baseUrl}/standards/${id}/rsvps/${eventId}`, undefined);
    const rsvpEvents = data as string[];
    return rsvpEvents;
  };
  const fetchLikeEvent = async (id: string, eventId: string) => {
    const { data } = await post(`${baseUrl}/standards/${id}/likes`, { eventId }, undefined);
    const likedEvents = data as string[];
    fetchEventLiked(eventId);
    return likedEvents;
  };
  const fetchUnlikeEvent = async (id: string, eventId: string) => {
    const { data } = await del(`${baseUrl}/standards/${id}/likes/${eventId}`, undefined);
    const likedEvents = data as string[];
    return likedEvents;
  };
  const fetchLikeVendor = async (userId: string, vendorId: string) => {
    const { data } = await post(
      `${baseUrl}/standards/${userId}/vendors/likes`,
      { vendorId },
      undefined,
    );
    const likedVendors = data as string[];
    fetchVendorLiked(vendorId);
    return likedVendors;
  };
  const fetchUnlikeVendor = async (id: string, vendorId: string) => {
    const { data } = await del(`${baseUrl}/standards/${id}/vendors/likes/${vendorId}`, undefined);
    const likedVendors = data as string[];
    return likedVendors;
  };
  const fetchAcceptStandardTermsAndConditions = async (standardId: string): Promise<void> => {
    const address = `${baseUrl}/standards/${standardId}/terms-and-conditions`;
    await get(address, undefined);
  };
  const fetchNotifications = async (standardId: string): Promise<Notification[]> => {
    const address = `${baseUrl}/standards/${standardId}/notifications`;
    const response = await get(address, undefined);
    return response.data;
  };

  return {
    fetchStandards,
    fetchCreateStandard,
    fetchStandard,
    fetchLikeVendor,
    fetchUnlikeVendor,
    fetchUpdateStandard,
    fetchAttendEvent,
    fetchUnattendEvent,
    fetchLikeEvent,
    fetchUnlikeEvent,
    fetchAcceptStandardTermsAndConditions,
    fetchNotifications,
  };
};
