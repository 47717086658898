import { Stack } from '@mui/material';
import React from 'react';
import { Message as MessageComponent } from './Message';
import { Paper } from './Paper';

export type Message = {
  _id: string;
  eventId: string;
  vendorId: string;
  vendorName: string;
  timestamp: number;
  message: string;
};

type MessagesListProps = {
  messages: Message[];
  onUpdate: (message: Message) => void;
  onDelete: (message: Message) => void;
};
export const MessagesList: React.FC<MessagesListProps> = ({ messages, onUpdate, onDelete }) => {
  return (
    <Paper elevation={1} style={{ width: '400px', padding: '3rem' }}>
      <Stack spacing={'2rem'}>
        {messages.map((message) => (
          <MessageComponent 
            key={message._id} 
            message={message} 
            onUpdate={onUpdate} 
            onDelete={onDelete}
          />
        ))}
      </Stack>
    </Paper>
  );
};
