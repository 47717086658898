import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSubscribeMutation } from '../../mutations/useSubscribeMutation';
import { REGISTER_SUCCESS, useToast } from '../../hooks/useToast';
import { TVendorForm } from '../../shared/vendors';
import { useAuth } from '../../auth/useAuth';
import { getStandardUser, getVendorUser } from '../../utils/userUtils';

export const useRegister = () => {
  const { user, setUser } = useAuth();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { mutateAsync } = useSubscribeMutation();
  const [error, setError] = React.useState<string>();

  const registerVendor = async (data: TVendorForm, setIsSubmitting: (bool: boolean) => void) => {
    try {
      if (!user) return setError('Unable to register. Please try again.');
      setIsSubmitting(true);
      const form = {
        ...data,
        subscription: 'vendor',
        acceptedTermsAndConditions: true,
      };
      const result = await mutateAsync({ user, form });
      if (result.ok) {
        const newAuth0User = (await result.json()).data;
        switch (newAuth0User.app_metadata.subscription) {
          case 'standard': {
            const standard = getStandardUser(newAuth0User);
            setUser(standard);
            break;
          }
          case 'vendor': {
            const vendor = getVendorUser(newAuth0User);
            setUser(vendor);
            break;
          }
        }
        navigate('/');
        showToast(REGISTER_SUCCESS, 'success');
      } else {
        throw new Error('Could not register. Please try again.');
      }
    } catch (err) {
      setError('Could not register. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return { registerVendor, error };
};
