import { Stack } from '@mui/material';
import { ClearIcon } from '../icons';
import { Text } from '../text';
import { styled } from 'styled-components';
import { Colors } from '../../theme/colors';

const Layout = styled(Stack)`
  padding: 0.5rem;
  border-radius: 10px;
  box-shadow: 1px 1px 3px 1px lightgrey;
`;

const Delete = styled(ClearIcon)`
  border-radius: 10px;

  &:hover {
    cursor: pointer;
    box-shadow: 0 0 2px 1px black;
  }
  &:active {
    cursor: pointer;
    box-shadow: 0 0 5px 2px black;
  }
`;

type Props = {
  name: string;
  onDelete: () => void;
};

export const Keyword = (props: Props) => {
  const { name, onDelete } = props;

  return (
    <Layout
      direction="row"
      style={{ background: Colors.primary }}
      spacing="1rem"
      alignItems={'center'}
    >
      <Text color="white">{name}</Text>
      <Delete onClick={onDelete} />
    </Layout>
  );
};
