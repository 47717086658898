import { useQuery } from 'react-query';
import { getVendorById } from '../api/vendors';

export const useGetVendorByIdQuery = (vendorId: string | undefined) => {
  return useQuery({
    queryKey: ['getVendorById', vendorId],
    queryFn: () => getVendorById(vendorId),
    enabled: !!vendorId,
  });
};
