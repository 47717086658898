import { DateTimeRepeat } from '../components/inputs/EventDateTimePicker/types';
import { DAYS, DateTime, Day } from '../shared/misc';

export const getDayFromAbbreviation = (abr: Day) => {
  return DAYS[abr];
};

export const getReccurringLabel = (dateTime: DateTimeRepeat) => {
  switch (dateTime.type) {
    case 'Does not repeat':
      return `Does Not Repeat`;
    case 'Repeat Daily':
      return `(Every Day)`;
    case 'Repeat Weekly':
      return `(Every ${dateTime.days?.join(', ')})`;
  }
};
