import { Stack, Divider } from '@mui/material';
import React from 'react';
import { Message as MessageType } from './MessagesList';
import { Text } from './text';
import TextField from './inputs/TextField';
import { Edit } from './icons/Edit';
import { Checkmark } from './icons/Checkmark';
import { Delete } from './icons/Delete';
import { Paper } from './Paper';
import { useAuth } from '../auth/useAuth';
import { isAdminUser } from '../utils/userUtils';

type MessageProps = {
  message: MessageType;
  onUpdate: (message: MessageType) => void;
  onDelete: (message: MessageType) => void;
};
export const Message: React.FC<MessageProps> = ({ message, onDelete, onUpdate }) => {
  const { user } = useAuth();
  const [text, setText] = React.useState<string>();
  const [isEditing, setIsEditing] = React.useState<boolean>(false);

  const isSameUser = (vendorId: string) => {
    return user?._id === vendorId;
  };

  const handleStartEditing = () => {
    setIsEditing(true);
    setText(message.message);
  };

  const handleFinishEditing = () => {
    if (text && text.trim().length > 0) {
      onUpdate({
        ...message,
        message: text,
      });
      setIsEditing(false);
      setText(undefined);
    }
  };

  return (
    <Paper elevation={2} style={{ padding: '1rem', borderRadius: '1rem' }}>
      <Stack>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Text fontWeight="bold">{message.vendorName}</Text>
          <Text style={{ marginLeft: '1rem' }}>{new Date(message.timestamp).toLocaleString()}</Text>
          {isSameUser(message.vendorId) && !isEditing ? (
            <Edit onClick={handleStartEditing} fontSize={'small'} />
          ) : isSameUser(message.vendorId) ? (
            <Checkmark onClick={handleFinishEditing} fontSize="small" />
          ) : null}
          {(isAdminUser(user) || isSameUser(message.vendorId)) && (
            <Delete onClick={() => onDelete(message)} fontSize={'small'} />
          )}
        </Stack>
        <Divider flexItem />
        <br />
        {!isEditing ? (
          <Text>{message.message}</Text>
        ) : (
          <TextField multiline value={text} onChange={setText} />
        )}
      </Stack>
    </Paper>
  );
};
