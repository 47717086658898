import { Routes, Route, Navigate } from 'react-router-dom';
import { Layout } from '../components/layout/Layout';
import { HomePage } from '../pages/home/HomePage';
import { LoadingText } from '../components/Loading';
import { AboutUsPage } from '../pages/home/AboutUsPage';
import { BecomeAMemberPage } from '../pages/home/BecomeAMemberPage';
import { BrowseEventsPage } from '../pages/home/BrowseEventsPage';
import { ContactUsPage } from '../pages/home/ContactUsPage';
import { EventPage } from '../pages/shared/EventPage';
import { VendorPage } from '../pages/shared/VendorPage';
import { SubscriptionPage } from '../pages/auth/SubscriptionPage';
import { useAuth } from '../auth/useAuth';
import { CreateVendorPage } from '../pages/admin/CreateVendorPage/CreateVendorPage';
import { SearchPage } from '../pages/shared/SearchPage';
import { protectBySubscription } from '../auth/PrivateRoute';
import { EventStatisticsPage } from '../pages/shared/EventStatisticsPage';
import { VendorStatisticsPage } from '../pages/shared/VendorStatisticsPage';
import { MessagesPage } from '../pages/shared/MessagesPage';
import { CalendarPage } from '../pages/shared/CalendarPage';
import { ProfilePage as StandardProfilePage } from '../pages/standard/ProfilePage';
import { CreateEventPage as VendorCreateEventPage } from '../pages/vendor/CreateEventPage';
import { CreateEventPage as AdminCreateEventPage } from '../pages/admin/CreateEventPage/CreateEventPage';
import { UpdateEventPage } from '../pages/admin/EditEventPage/EditEventPage';
import { ActivationPage } from '../pages/admin/ActivationPage';
import { VendorProfilePage } from '../pages/vendor/ProfilePage/VendorProfilePage';
import { NotificationsPage } from '../pages/standard/NotificationsPage';
import { VendorEditPage } from '../pages/vendor/VendorEditPage';

export const Router = () => {
  const { user, isLoading, isAuthenticated, error } = useAuth();

  if (isLoading) return <LoadingText />;
  if (error) throw new Error(error.message); // TODO - SSO - Throw? Need error boundary?
  if (isAuthenticated && (!user?.subscription || user.subscription === 'unsubscribed'))
    return <SubscriptionPage />;

  const ProtectedSearchPage = protectBySubscription({
    user,
    element: SearchPage,
    subscriptions: ['standard', 'vendor', 'admin'],
  });
  const ProtectedEventStatisticsPage = protectBySubscription({
    user,
    element: EventStatisticsPage,
    subscriptions: ['vendor', 'admin'],
  });
  const ProtectedVendorStatisticsPage = protectBySubscription({
    user,
    element: VendorStatisticsPage,
    subscriptions: ['vendor', 'admin'],
  });
  const ProtectedCalendarPage = protectBySubscription({
    user,
    element: CalendarPage,
    subscriptions: ['standard', 'vendor', 'admin'],
  });
  const ProtectedMessagePage = protectBySubscription({
    user,
    element: MessagesPage,
    subscriptions: ['standard', 'vendor', 'admin'],
  });
  const ProtectedProfilePage = protectBySubscription({
    user,
    element: user?.subscription === 'standard' ? StandardProfilePage : VendorProfilePage,
    subscriptions: ['standard', 'vendor'],
  });
  const ProtectedVendorEditPage = protectBySubscription({
    user,
    element: VendorEditPage,
    subscriptions: ['vendor', 'admin'],
  });
  const ProtectedCreateEventPage = protectBySubscription({
    user,
    element: user?.subscription === 'vendor' ? VendorCreateEventPage : AdminCreateEventPage,
    subscriptions: ['vendor', 'admin'],
  });
  const ProtectedUpdateEventPage = protectBySubscription({
    user,
    element: UpdateEventPage,
    subscriptions: ['vendor', 'admin'],
  });
  const ProtectedCreateVendorPage = protectBySubscription({
    user,
    element: CreateVendorPage,
    subscriptions: ['admin'],
  });
  // const ProtectedActivationPage = protectBySubscription({
  //   user,
  //   element: ActivationPage,
  //   subscriptions: ['admin'],
  // });
  const ProtectedNotificationsPage = protectBySubscription({
    user,
    element: NotificationsPage,
    subscriptions: ['standard'],
  });

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<AboutUsPage />} />
        <Route path="/join" element={<BecomeAMemberPage />} />
        <Route path="/contact-us" element={<ContactUsPage />} />
        <Route path="*" element={<Navigate to={'/'} />} />
      </Route>
    </Routes>
  );
};
