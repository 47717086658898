import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/useAuth';
import { useDialog } from '../context/DialogProvider';
import { useAddEventToCalendarMutation } from '../mutations/useAddEventToCalendarMutation';
import { useBlockEventMutation } from '../mutations/useBlockEventMutation';
import { useDislikeEventMutation } from '../mutations/useDislikeEventMutation';
import { useFollowVendorMutation } from '../mutations/useFollowVendorMutation';
import { useLikeEventMutation } from '../mutations/useLikeEventMutation';
import { useRemoveEventFromCalendarMutation } from '../mutations/useRemoveEventFromCalendarMutation';
import { useUnblockEventMutation } from '../mutations/useUnblockEventMutation';
import { useUnfollowVendorMutation } from '../mutations/useUnfollowVendorMutation';
import { CopyDialogContents } from '../components/dialogs/CopyDialogContents';
import { useBlockUserMutation } from '../mutations/useBlockUserMutation';
import { useUnblockUserMutation } from '../mutations/useUnblockUserMutation';
import { usePublicUser } from '../context/PublicUserProvider';

export const useActionButtons = () => {
  const { user, setUser } = useAuth();
  const { publicToggleLikeEvent } = usePublicUser();
  const navigate = useNavigate();
  const { setOpen } = useDialog();
  const {
    mutateAsync: likeEvent,
    error: likeEventError,
    isLoading: isLoadingLike,
  } = useLikeEventMutation();
  const {
    mutateAsync: dislikeEvent,
    error: dislikeEventError,
    isLoading: isLoadingDislike,
  } = useDislikeEventMutation();
  const {
    mutateAsync: addToCalendar,
    error: addToCalendarError,
    isLoading: isLoadingAddEventToCalendar,
  } = useAddEventToCalendarMutation();
  const {
    mutateAsync: removeFromCalendar,
    error: removeEventFromCalendarError,
    isLoading: isLoadingRemoveEventFromCalendar,
  } = useRemoveEventFromCalendarMutation();
  const {
    mutateAsync: blockEvent,
    error: blockEventError,
    isLoading: isLoadingBlockEvent,
  } = useBlockEventMutation();
  const {
    mutateAsync: unblockEvent,
    error: unblockEventError,
    isLoading: isLoadingUnblockEvent,
  } = useUnblockEventMutation();
  const {
    mutateAsync: followVendor,
    error: followVendorError,
    isLoading: isLoadingFollowVendor,
  } = useFollowVendorMutation();
  const {
    mutateAsync: unfollowVendor,
    error: unfollowVendorError,
    isLoading: isLoadingUnfollowVendor,
  } = useUnfollowVendorMutation();
  const {
    mutateAsync: blockUser,
    error: blockUserError,
    isLoading: isLoadingBlockUser,
  } = useBlockUserMutation();
  const {
    mutateAsync: unblockUser,
    error: unblockUserError,
    isLoading: isLoadingUnblockUser,
  } = useUnblockUserMutation();

  const toggleLikeClick = async (isLiked: boolean, eventId: string) => {
    if (user) {
      if (isLiked) {
        const newUser = await dislikeEvent({ userId: user._id, eventId });
        setUser(newUser);
      } else {
        const newUser = await likeEvent({ userId: user._id, eventId });
        setUser(newUser);
      }
    } else {
      publicToggleLikeEvent(eventId);
    }
  };

  const toggleCalendarClick = async (isOnCalendar: boolean, eventId: string) => {
    if (user) {
      if (isOnCalendar) {
        const newUser = await removeFromCalendar({ userId: user._id, eventId });
        setUser(newUser);
      } else {
        const newUser = await addToCalendar({ userId: user._id, eventId });
        setUser(newUser);
      }
    } else {
      // TODO - Public calendar event
    }
  };

  const toggleFollowVendorClick = async (isFollowingVendor: boolean, vendorId: string) => {
    if (user) {
      if (isFollowingVendor) {
        const newUser = await followVendor({ userId: user._id, vendorId });
        setUser(newUser);
      } else {
        const newUser = await unfollowVendor({ userId: user._id, vendorId });
        setUser(newUser);
      }
    } else {
      // TODO - Public follow vendor
    }
  };

  const toggleBlockEventClick = async (blocked: boolean, eventId: string) => {
    if (!blocked) {
      await blockEvent({ eventId });
    } else {
      await unblockEvent({ eventId });
    }
  };

  const toggleBlockUserClick = async (blocked: boolean, userId: string) => {
    if (!blocked) {
      await blockUser({ userId });
    } else {
      await unblockUser({ userId });
    }
  };

  const onEditEventClick = (eventId: string) => navigate(`/events/${eventId}/edit`);
  const onEditVendorClick = (eventId: string) => navigate(`/vendors/${eventId}/edit`);
  const onMessagesClick = (eventId: string) => navigate(`/events/${eventId}/messages`);
  const onShareClick = () => setOpen(true, <CopyDialogContents />);
  const onEventStatisticsClick = (eventId: string) => navigate(`/events/${eventId}/statistics`);
  const onVendorStatisticsClick = (eventId: string) => navigate(`/vendors/${eventId}/statistics`);

  const likeToggleMutation = {
    isLoading: isLoadingLike || isLoadingDislike,
    error: likeEventError || dislikeEventError,
  };
  const calendarToggleMutation = {
    isLoading: isLoadingAddEventToCalendar || isLoadingRemoveEventFromCalendar,
    error: addToCalendarError || removeEventFromCalendarError,
  };
  const blockEventToggleMutation = {
    isLoading: isLoadingBlockEvent || isLoadingUnblockEvent,
    error: blockEventError || unblockEventError,
  };
  const followVendorToggleMutation = {
    isLoading: isLoadingFollowVendor || isLoadingUnfollowVendor,
    error: followVendorError || unfollowVendorError,
  };
  const blockUserToggleMutation = {
    isLoading: isLoadingBlockUser || isLoadingUnblockUser,
    error: blockUserError || unblockUserError,
  };

  return {
    likeToggleMutation,
    calendarToggleMutation,
    blockEventToggleMutation,
    followVendorToggleMutation,
    blockUserToggleMutation,
    toggleLikeClick,
    toggleCalendarClick,
    toggleBlockEventClick,
    toggleFollowVendorClick,
    toggleBlockUserClick,
    onEditEventClick,
    onEditVendorClick,
    onMessagesClick,
    onShareClick,
    onEventStatisticsClick,
    onVendorStatisticsClick,
  };
};
