import { useMutation } from 'react-query';
import { addEventToCalendar } from '../api/standards';

type AddEventToCalendarOptions = {
  userId: string;
  eventId: string;
};
export const useAddEventToCalendarMutation = () => {
  return useMutation({
    mutationFn: ({ userId, eventId }: AddEventToCalendarOptions) =>
      addEventToCalendar(userId, eventId),
  });
};
