import theme from './theme';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './routes/Router';
import { ThemeProvider } from '@mui/material';
import { PublicUserProvider } from './context/PublicUserProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Bounce, ToastContainer } from 'react-toastify';
import { DialogProvider } from './context/DialogProvider';
import { Auth0ProviderWithHistory } from './auth/Auth0ProviderWithHistory';
import { AuthProvider } from './auth/useAuth';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'react-toastify/dist/ReactToastify.css';

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <PublicUserProvider>
          <Auth0ProviderWithHistory>
            <AuthProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ThemeProvider theme={theme}>
                  <DialogProvider>
                    <Router />
                    <ToastContainer
                      closeOnClick
                      autoClose={3000}
                      position="bottom-center"
                      theme="light"
                      transition={Bounce}
                      pauseOnFocusLoss={false}
                    />
                  </DialogProvider>
                </ThemeProvider>
              </LocalizationProvider>
            </AuthProvider>
          </Auth0ProviderWithHistory>
        </PublicUserProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default App;
