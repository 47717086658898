import { Box, Divider, Stack } from '@mui/material';
import React from 'react';
import { DATE_SHORT } from '../formatting';
import { TEvent } from '../shared/events';
import { ExpandMoreIcon, ExpandLessIcon } from './icons';
import { styled } from 'styled-components';
import { Text } from './text';
import { useAuth } from '../auth/useAuth';
import { EventItem } from './items/EventItem';
import { useNavigate } from 'react-router-dom';

export type EventDateMap = {
  [key: string]: TEvent[];
};
type EventsByMonth = {
  [month: string]: TEvent[];
};
type EventsByMonthByDate = {
  [month: string]: EventDateMap;
};

const DropdownButton = styled(Stack)`
  cursor: pointer;
  border-radius: 5px;
  background-color: #00000018;

  &:hover {
    background-color: #00000072;
  }
`;

type CalendarViewProps = {
  events: TEvent[];
};
export const CalendarView: React.FC<CalendarViewProps> = ({ events }) => {
  const navigate = useNavigate();
  const [eventsFormatted, setEventsFormatted] = React.useState<EventsByMonthByDate>({});
  const [isExpanded, setIsExpanded] = React.useState<boolean>(true);

  React.useEffect(() => {
    const sortedEvents = sortEventsByMonth(events);
    const eventsByMonthAndDate = createMonthMap(sortedEvents);
    setEventsFormatted(eventsByMonthAndDate);
  }, [events]);

  const getEventCount = (dateMap: EventDateMap): number => {
    return Object.keys(dateMap).reduce<number>((acc, date) => {
      return acc + dateMap[date].length;
    }, 0);
  };

  const createMonthMap = (eventsByMonth: EventsByMonth): EventsByMonthByDate => {
    return Object.keys(eventsByMonth).reduce<EventsByMonthByDate>((acc, month) => {
      const eventsInMonth = eventsByMonth[month];
      const eventsMap = createDateMap(eventsInMonth);
      acc[month] = eventsMap;
      return acc;
    }, {});
  };

  const sortEventsByMonth = (list: TEvent[]): EventsByMonth => {
    return list
      .sort((a, b) => new Date(a.dateTime.start).getTime() - new Date(b.dateTime.start).getTime())
      .reduce<EventsByMonth>((acc, listEvent) => {
        const date = new Date(listEvent.dateTime.start);
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.toLocaleString('default', { year: 'numeric' });
        const fullDate = `${month}, ${year}`;
        if (!acc[fullDate]) acc[fullDate] = [];
        acc[fullDate].push(listEvent);
        return acc;
      }, {});
  };

  const createDateMap = (list: TEvent[]): EventDateMap => {
    return list.reduce<EventDateMap>((acc, value) => {
      const date = value.dateTime.start;
      const formattedDate = new Date(date).toLocaleDateString(undefined, DATE_SHORT as any);
      if (!acc[formattedDate]) acc[formattedDate] = [];
      acc[formattedDate].push(value);
      return acc;
    }, {});
  };

  return (
    <Stack spacing={'2rem'}>
      {Object.keys(eventsFormatted).map((month) => {
        const dateMap = eventsFormatted[month];
        return (
          <Stack key={month} direction={'row'}>
            <Box style={{ width: '100%' }}>
              <Stack spacing={'0rem'}>
                <Stack direction={'row'} spacing={'1rem'} alignItems={'center'}>
                  <DropdownButton
                    onClick={() => setIsExpanded(!isExpanded)}
                    direction={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    {!isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                  </DropdownButton>
                  <Divider flexItem orientation="vertical" style={{ marginRight: '1rem' }} />
                  <Text fontSize="32px" fontWeight="bold">
                    {month.toUpperCase()}
                  </Text>
                  <Divider flexItem orientation="vertical" style={{ marginRight: '1rem' }} />
                  <Text>{`Events: ${getEventCount(dateMap)}`}</Text>
                </Stack>
                {isExpanded &&
                  Object.keys(dateMap).map((date) => (
                    <Stack key={date} spacing={'0.5rem'}>
                      {dateMap[date].map((event) => (
                        <EventItem
                          key={event._id}
                          event={event}
                          onClick={() => navigate(`/events/${event._id}`)}
                          onError={console.log}
                        />
                      ))}
                    </Stack>
                  ))}
              </Stack>
            </Box>
          </Stack>
        );
      })}
    </Stack>
  );
};
