import { useMutation } from 'react-query';
import { followVendor } from '../api/standards';

type FollowVendorOptions = {
  userId: string;
  vendorId: string;
};
export const useFollowVendorMutation = () => {
  return useMutation({
    mutationFn: ({ userId, vendorId }: FollowVendorOptions) => followVendor(userId, vendorId),
  });
};
