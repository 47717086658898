import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TVendor, TVendorForm } from '../../shared/vendors';
import { UPDATE_PROFILE_SUCCESS, useToast } from '../../hooks/useToast';
import { getNetworkErrorMessage } from '../../validation';
import { useAuth } from '../../auth/useAuth';
import { useUpdateUserMutation } from '../../mutations/useUpdateStandardMutation';

export const useUpdateVendor = () => {
  const { user, setUser } = useAuth();
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { mutateAsync } = useUpdateUserMutation();
  const [error, setError] = React.useState<string>();

  const updateVendor = async (
    data: TVendorForm,
    vendor: TVendor,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      setSubmitting(true);
      const newVendor = await mutateAsync({ id: vendor._id, data });
      if (user?._id === vendor._id) setUser(newVendor);
      navigate(0);
      showToast(UPDATE_PROFILE_SUCCESS, 'success'); // TODO - This does not show
    } catch (err) {
      const message = getNetworkErrorMessage(err);
      message && setError(message);
      message && showToast(message, 'error');
    } finally {
      setSubmitting(false);
    }
  };

  return { updateVendor, error };
};
