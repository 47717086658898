import React from 'react';
import { ShareOutlinedIcon } from '.';
import Icon from './Icon';

export type Props = {
    onClick: () => void;
};
export const Share: React.FC<Props> = ({ onClick }) => {
    return (
        <Icon tooltip={'Share'} onClick={onClick}>
            <ShareOutlinedIcon fontSize="large" />
        </Icon>
    );
};
