import React from 'react';
import { BlockOutlinedIcon } from '.';
import Icon from './Icon';
import { useTheme } from '@mui/material';

export type Props = {
  isToggled?: boolean;
  onClick: () => void;
  disabled?: boolean;
};
export const Block: React.FC<Props> = ({ isToggled, disabled, onClick }) => {
  const theme = useTheme();
  const color = isToggled ? theme.palette.warning.main : theme.palette.error.main;
  const text = isToggled ? 'Unblock' : 'Block';
  const displayColor = disabled ? 'lightgrey' : color;
  return (
    <Icon disabled={disabled} tooltip={text} onClick={onClick}>
      <BlockOutlinedIcon style={{ color: displayColor }} fontSize="large" />
    </Icon>
  );
};
