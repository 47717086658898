import React from 'react';
import { Box, Stack } from '@mui/material';
import { styled } from 'styled-components';
import Container from '../Container';
import { Text } from '../text';
import { PALETTE, BACKGROUND } from '../../theme/palette';

const Body = styled(Box)`
  flex: 1;
  margin-top: 1rem;
  padding: 0 1rem;
`;

type Props = {
  header?: string;
  subheader?: string;
  maxWidth?: string;
};
export const Page: React.FC<React.PropsWithChildren<Props>> = ({
  header,
  subheader,
  maxWidth,
  children,
}) => {
  return (
    <Stack
      spacing={'1rem'}
      alignItems={'center'}
      flex={'1'}
      height={'100vh'}
      style={{ background: BACKGROUND }}
    >
      <br />
      <br />
      <Stack alignItems={'center'} style={{ wordBreak: 'break-word' }}>
        <Container maxWidth="800px" style={{ wordBreak: 'break-word' }}>
          {header && (
            <Text textAlign="center" fontSize="48px" color={PALETTE.primary.main}>
              {header}
            </Text>
          )}
          {subheader && (
            <Text textAlign="center" fontSize="32px">
              {subheader}
            </Text>
          )}
        </Container>
      </Stack>
      <Container maxWidth={maxWidth}>
        <Body>{children}</Body>
        <br />
        <br />
        <br />
      </Container>
    </Stack>
  );
};
