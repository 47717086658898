import { Button, Stack, Typography } from '@mui/material';
import Container from '../../components/Container';
import { Page } from '../../components/layout/page';
import Dropdown from '../../components/inputs/Dropdown';
import TextField from '../../components/inputs/TextField';
import DatePicker from '../../components/inputs/DatePicker';
import { PRONOUNS } from '../../components/forms/StandardRegisterForm';
import React from 'react';
import { useStandardsAPI } from '../../hooks/useStandardsAPI';
import { useNavigate } from 'react-router-dom';
import { getNetworkErrorMessage } from '../../validation';
import { UPDATE_PROFILE_SUCCESS, useToast } from '../../hooks/useToast';
import { LoadingText } from '../../components/Loading';
import { useAuth } from '../../auth/useAuth';
import { TStandard } from '../../shared/standards';

export const ProfilePage = () => {
  const { user, setUser } = useAuth();
  const standard = user as TStandard;

  const navigate = useNavigate();
  const { showToast } = useToast();
  const { fetchUpdateStandard } = useStandardsAPI();
  const [pronouns, setPronouns] = React.useState<string>(standard.pronouns || PRONOUNS[0]);
  const [birthday, setBirthday] = React.useState<number>(standard.birthday);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();

  if (!user) return <LoadingText />;

  const handleUpdateClick = async () => {
    if (!pronouns || !birthday) {
      setError('Must fill out all required information.');
      return;
    }
    try {
      setIsLoading(true);
      const form = {
        pronouns,
        birthday,
      };
      // const standard = await fetchUpdateStandard(user._id, form);
      showToast(UPDATE_PROFILE_SUCCESS, 'success');
      setUser(standard);
    } catch (err) {
      const message = getNetworkErrorMessage(err);
      message && setError(message);
      message && showToast(message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page header={'PROFILE'} maxWidth="800px">
      <Container maxWidth="400px">
        <Stack spacing={'1rem'}>
          <TextField
            required
            disabled
            label={'EMAIL'}
            // error={emailError}
            onChange={() => {}}
          />
          <TextField
            required
            disabled
            label={'USERNAME'}
            // error={usernameError}
            onChange={() => {}}
          />
          <TextField required disabled label={'PASSWORD'} type="password" onChange={() => {}} />
          <Dropdown
            required
            label={'PRONOUNS'}
            value={pronouns}
            options={PRONOUNS}
            onChange={(s) => setPronouns(s)}
          />
          <DatePicker
            required
            value={new Date(birthday)}
            label="DATE OF BIRTH"
            // error={birthdayError}
            onChange={(d) => d && setBirthday(d.getTime())}
          />
          <Button variant="contained" onClick={handleUpdateClick} disabled={isLoading}>
            Update
          </Button>
          {error && (
            <Typography color={'error'} textAlign={'center'}>
              {error}
            </Typography>
          )}
        </Stack>
      </Container>
    </Page>
  );
};
