import React from 'react';
import { ContentCopyOutlinedIcon } from '.';
import Icon from './Icon';

export type Props = {
    onClick: () => void;
};
export const Copy: React.FC<Props> = ({ onClick }) => {
    return (
        <Icon tooltip={'Copy'} onClick={onClick}>
            <ContentCopyOutlinedIcon fontSize='medium' />
        </Icon>
    );
};
