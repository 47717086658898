import { useMutation, useQueryClient } from 'react-query';
import { createEvent } from '../api/events';
import { TEventForm } from '../shared/events';

export const useCreateEventMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['createEvent'],
    mutationFn: (data: TEventForm) => createEvent(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getEvents'] });
    },
  });
};
