import { getBaseUrl } from '../utils/api';

const baseUrl = getBaseUrl();

type QueryParams = {
  [key: string]: string;
};
type CreateQueryParams = {
  [key: string]: unknown;
};
export const createQueryParams = (params: CreateQueryParams): QueryParams => {
  return Object.keys(params).reduce<QueryParams>((acc, key) => {
    const value = params[key];
    acc[key] = `${value}`;
    return acc;
  }, {});
};

export const createFetch = (url: string, method: string, body?: string, token?: string) => {
  const headers = {
    'content-type': 'application/json',
    'authorization': `Bearer ${token}`,
  };
  const options = {
    method,
    headers,
    body,
  };
  return fetch(`${baseUrl}${url}`, options);
};
