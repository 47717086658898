import { Stack } from '@mui/material';
import { Colors } from '../../theme/colors';
import { TimeField } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { Text } from '../text';

type Props = {
  // Mandatory
  onChange: (date: Dayjs) => void;

  // Optional
  value: Dayjs;
  label?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
};
const TimePicker = ({ value, label, error, onChange, required, disabled }: Props) => {
  const star = required ? '*' : '';
  const labelText = `${label}` + star;

  return (
    <Stack>
      {label && <Text>{labelText}</Text>}
        <TimeField
          disabled={disabled}
          value={dayjs(value) as any}
          onChange={(date: Dayjs | null) => {
            date && onChange(date);
          }}
          slotProps={{
            textField: {
              helperText: error,
              error: error !== undefined,
              sx: {
                input: { color: Colors.primary },
                label: { color: Colors.primary },
              },
            },
          }}
        />
    </Stack>
  );
};

export default TimePicker;
