import { del, get, patch, post } from '../utils/networking';
import { EventStatistics } from '../types/events';
import { getBaseUrl } from '../utils/api';
import { Message } from '../components/MessagesList';
import { TEvent, TEventForm } from '../shared/events';

const baseUrl = getBaseUrl();

export const useEventsAPI = () => {
  const fetchUpdateEvent = async (eventId: string, form: TEventForm): Promise<TEvent> => {
    const address = `${baseUrl}/events/${eventId}`;
    const { data } = await patch(address, undefined, form);
    return data as TEvent;
  };

  const fetchDeleteEvent = async (eventId: string): Promise<void> => {
    const address = `${baseUrl}/events/${eventId}`;
    await del(address, undefined);
  };

  const fetchEventStatistics = async (eventId: string) => {
    const address = `${baseUrl}/events/${eventId}/stats`;
    const { data } = await get(address, undefined);
    return data as EventStatistics;
  };

  // Public
  const fetchEventViewed = async (eventId: string) => {
    const address = `${baseUrl}/events/${eventId}/stats/views`;
    await get(address, undefined);
  };
  // Public
  const fetchEventLiked = async (eventId: string) => {
    const address = `${baseUrl}/events/${eventId}/stats/likes`;
    await get(address, undefined);
  };
  const fetchEventRsvpd = async (eventId: string) => {
    const address = `${baseUrl}/events/${eventId}/stats/rsvps`;
    await get(address, undefined);
  };

  const fetchMessagesForEvent = async (eventId: string) => {
    const address = `${baseUrl}/events/${eventId}/messages`;
    const { data } = await get(address, undefined);
    return data as Message[];
  };

  const fetchPostMessageToEvent = async (eventId: string, message: string, vendorId: string) => {
    const address = `${baseUrl}/events/${eventId}/messages`;

    const body = {
      message,
      vendorId,
    };

    const { data } = await post(address, body, undefined);
    return data as Message[];
  };

  const fetchDeleteMessage = async (eventId: string, messageId: string) => {
    const address = `${baseUrl}/events/${eventId}/messages/${messageId}`;
    const { data } = await del(address, undefined);
    return data as Message[];
  };

  const fetchUpdateMessage = async (eventId: string, newMessage: Message) => {
    const address = `${baseUrl}/events/${eventId}/messages/${newMessage._id}`;
    const { data } = await patch(address, undefined, newMessage);
    return data as Message[];
  };

  return {
    fetchUpdateEvent,
    fetchDeleteEvent,
    fetchEventStatistics,
    fetchEventViewed,
    fetchEventLiked,
    fetchEventRsvpd,
    fetchMessagesForEvent,
    fetchPostMessageToEvent,
    fetchDeleteMessage,
    fetchUpdateMessage,
  };
};
