import { Stack, Typography } from '@mui/material';
import React from 'react';
import Dropdown from '../../components/inputs/Dropdown';
import { Page } from '../../components/layout/page';
import { BarChart } from '@mui/x-charts';
import { useParams } from 'react-router-dom';
import { getNetworkErrorMessage } from '../../validation';
import { useVendorsAPI } from '../../hooks/useVendorsAPI';
import { Statistic, VendorStatistics } from '../../types/users/vendor';
import { Paper } from '../../components/Paper';
import { Text } from '../../components/text';
import { BLUE, ORANGE, PINK } from '../../theme/palette';
import { LoadingText } from '../../components/Loading';
import { TVendor } from '../../shared/vendors';

type ChartData = {
  label: string; // Label for the stat
  data: number[]; // Each column
  stack: string; // 'A' is an identifier for the stack column
};

const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const MILLISECONDS_PER_DAY = 86400000;

const VIEWS = ['Vendor Views', 'Vendor Likes'];
const START_VIEW = VIEWS[0];

const TIMES = ['Past Week', 'Past Month', 'Past Year'];
const START_TIME = TIMES[0];

const getStartData = () => {
  return [
    {
      label: 'Public',
      data: [] as number[],
      stack: 'A',
      color: PINK.main,
    },
    {
      label: 'Standard',
      data: [] as number[],
      stack: 'A',
      color: BLUE.main,
    },
    {
      label: 'Vendor',
      data: [] as number[],
      stack: 'A',
      color: ORANGE.light,
    },
    {
      label: 'Admin',
      data: [] as number[],
      stack: 'A',
    },
  ];
};

const getNDaysInclusive = (first: number, last: number): Date[] => {
  const nDays = [];
  const lastDay = getNthDayPrior(last);
  nDays.push(lastDay);
  for (let i = 0; i < first - last - 1; i++) {
    const nextValue = lastDay.getTime() - (i + 1) * MILLISECONDS_PER_DAY;
    const nextDate = new Date(nextValue);
    nDays.unshift(nextDate);
  }
  return nDays;
};
const getNthDayPrior = (numberDays: number): Date => {
  const now = Date.now();
  const time = now - numberDays * MILLISECONDS_PER_DAY;
  return new Date(time);
};

const getLastNDays = (numberDays: number): Date[] => {
  const nDays = [];
  const today = new Date();
  nDays.push(today);
  for (let i = 0; i < numberDays - 1; i++) {
    const nextValue = today.getTime() - (i + 1) * MILLISECONDS_PER_DAY;
    const nextDate = new Date(nextValue);
    nDays.unshift(nextDate);
  }
  return nDays;
};

const formatPastWeekXAxis = () => {
  const days = getLastNDays(7);
  return days.map((date) =>
    date.toLocaleDateString(undefined, { weekday: 'short', day: 'numeric' }),
  );
};

const formatPastMonthXAxis = () => {
  const month = getLastNDays(28);
  const result = [];

  for (let i = 0; i < 4; i++) {
    const startDate = month[i * 7].toLocaleDateString(undefined, {
      month: 'short',
      day: 'numeric',
    });
    const endDate = month[i * 7 + 6].toLocaleDateString(undefined, {
      month: 'short',
      day: 'numeric',
    });
    result.push(`${startDate} - ${endDate}`);
  }
  return result;
};

const formatPastYearXAxis = () => {
  const months = [...MONTHS];
  const today = new Date();
  const month = today.toLocaleDateString(undefined, { month: 'short' });
  const indexOfMonth = months.indexOf(month);
  if (indexOfMonth < 0) throw new Error('Could not find month');
  const monthsToSplice = months.splice(0, indexOfMonth + 1);
  return [...months, ...monthsToSplice];
};

// COMPONENT //
export const VendorStatisticsPage = () => {
  const { id } = useParams();
  const { fetchVendorStatistics, fetchVendor } = useVendorsAPI();
  const [vendor, setVendor] = React.useState<TVendor>();
  const [viewSelection, setViewSelection] = React.useState<string>(START_VIEW);
  const [timeSelection, setTimeSelection] = React.useState<string>(START_TIME);
  const [stats, setStats] = React.useState<VendorStatistics>();
  const [data, setData] = React.useState<ChartData[]>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();

  React.useEffect(() => {
    loadStats();
    loadVendor();
  }, [id]);

  React.useEffect(() => {
    setStatsData();
  }, [stats, viewSelection, timeSelection]);

  if (!id) return <LoadingText />;

  const loadVendor = async () => {
    try {
      setIsLoading(true);
      const v = await fetchVendor(id);
      setVendor(v);
    } catch (err) {
      const message = getNetworkErrorMessage(err);
      message && setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  const loadStats = async () => {
    try {
      setIsLoading(true);
      const result = await fetchVendorStatistics(id);
      setStats(result);
    } catch (err) {
      const message = getNetworkErrorMessage(err);
      message && setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  const setStatsData = () => {
    if (!stats) return;
    const { views, likes } = stats;
    if (views && viewSelection === 'Vendor Views') {
      setData(getVendorData(views));
    }
    if (likes && viewSelection === 'Vendor Likes') {
      setData(getVendorData(likes));
    }
  };

  const getVendorData = (list: Statistic[]): ChartData[] => {
    switch (timeSelection) {
      case 'Past Week':
        return getPastWeekStats(list);
      case 'Past Month':
        return getPastMonthStats(list);
      case 'Past Year':
        return getPastYearStats(list);
    }
    return [];
  };

  const getPastWeekStats = (list: Statistic[]) => {
    const first = getNthDayPrior(7);
    const firstDate = setDateTimeToZero(first);
    const days = formatPastWeekXAxis();
    const dayMap = days.reduce<any>((acc, day) => {
      acc[day] = { Public: 0, Standard: 0, Vendor: 0, Admin: 0 };
      return acc;
    }, {});

    const week = list.reduce<any>((acc, stat) => {
      if (stat.timestamp > firstDate.getTime()) {
        const date = new Date(stat.timestamp);
        const day = date.toLocaleDateString(undefined, { weekday: 'short', day: 'numeric' });
        dayMap[day][stat.subscription]++;
      }
      return acc;
    }, dayMap);

    return Object.keys(week).reduce(
      (acc, day) => {
        const { Public, Standard, Vendor, Admin } = week[day];
        const pChart = acc.filter((c) => c.label === 'Public')[0];
        const sChart = acc.filter((c) => c.label === 'Standard')[0];
        const vChart = acc.filter((c) => c.label === 'Vendor')[0];
        const aChart = acc.filter((c) => c.label === 'Admin')[0];
        pChart.data.push(Public);
        sChart.data.push(Standard);
        vChart.data.push(Vendor);
        aChart.data.push(Admin);
        return acc;
      },
      [...getStartData()],
    );
  };

  const getPastYearStats = (list: Statistic[]) => {
    const first = getNthDayPrior(365);
    const firstDate = setDateTimeToZero(first);
    const months = formatPastYearXAxis();
    const monthsMap = months.reduce<any>((acc, month) => {
      acc[month] = { Public: 0, Standard: 0, Vendor: 0, Admin: 0 };
      return acc;
    }, {});

    const year = list.reduce<any>((acc, stat) => {
      if (stat.timestamp > firstDate.getTime()) {
        const date = new Date(stat.timestamp);
        const month = date.toLocaleDateString(undefined, { month: 'short' });
        monthsMap[month][stat.subscription]++;
      }
      return acc;
    }, monthsMap);

    return Object.keys(year).reduce(
      (acc, month) => {
        const { Public, Standard, Vendor, Admin } = year[month];
        const pChart = acc.filter((c) => c.label === 'Public')[0];
        const sChart = acc.filter((c) => c.label === 'Standard')[0];
        const vChart = acc.filter((c) => c.label === 'Vendor')[0];
        const aChart = acc.filter((c) => c.label === 'Admin')[0];
        pChart.data.push(Public);
        sChart.data.push(Standard);
        vChart.data.push(Vendor);
        aChart.data.push(Admin);
        return acc;
      },
      [...getStartData()],
    );
  };

  const getPastMonthStats = (list: Statistic[]) => {
    let startData = [...getStartData()];

    for (let i = 0; i < 4; i++) {
      const days = getNDaysInclusive(7 * i + 7, 7 * i);
      const firstDay = days[0];
      const lastDay = days[days.length - 1];

      const daysNames = days.map((date) =>
        date.toLocaleDateString(undefined, { weekday: 'short', day: 'numeric' }),
      );

      const dayMap = daysNames.reduce<any>((acc, day) => {
        acc[day] = { Public: 0, Standard: 0, Vendor: 0, Admin: 0 };
        return acc;
      }, {});

      const week = list.reduce<any>((acc, stat) => {
        if (stat.timestamp > firstDay.getTime() && stat.timestamp < lastDay.getTime()) {
          const date = new Date(stat.timestamp);
          const day = date.toLocaleDateString(undefined, { weekday: 'short', day: 'numeric' });
          dayMap[day][stat.subscription]++;
        }
        return acc;
      }, dayMap);

      const weekViews = Object.keys(week).reduce<any>(
        (acc, day) => {
          const { Public, Standard, Vendor, Admin } = week[day];
          acc.Public += Public;
          acc.Standard += Standard;
          acc.Vendor += Vendor;
          acc.Admin += Admin;
          return acc;
        },
        { Public: 0, Standard: 0, Vendor: 0, Admin: 0 },
      );

      Object.keys(weekViews).map((subscription) => {
        const data = weekViews[subscription];
        const chart = startData.filter((c) => c.label === subscription)[0];
        chart.data.unshift(data);
      });
    }

    return startData;
  };

  const setDateTimeToZero = (date: Date) => {
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  };

  const determineXAxisData = () => {
    switch (timeSelection) {
      case 'Past Week':
        return formatPastWeekXAxis();
      case 'Past Month':
        return formatPastMonthXAxis();
      case 'Past Year':
        return formatPastYearXAxis();
    }
  };

  const yAxisOptions = [
    {
      label: viewSelection,
    },
  ];

  const xAxisOptions: any = [
    {
      label: timeSelection,
      scaleType: 'band',
      data: determineXAxisData(),
    },
  ];

  return (
    <Page header={'VENDOR STATISTICS'} subheader={vendor?.companyName} maxWidth="500px">
      <Stack spacing={'1rem'} alignItems={'center'}>
        <Stack direction={'row'} spacing={'1rem'} justifyContent={'space-between'} width={'100%'}>
          <Dropdown
            label="Category"
            value={viewSelection}
            options={VIEWS}
            onChange={setViewSelection}
          />
          <Dropdown
            label="Timeline"
            value={timeSelection}
            options={TIMES}
            onChange={setTimeSelection}
          />
        </Stack>
        {isLoading && <LoadingText />}
        {!isLoading && data && (
          <Paper elevation={1}>
            <BarChart
              series={data.filter((c) => c.label !== 'Admin')}
              width={600}
              height={350}
              xAxis={xAxisOptions}
              yAxis={yAxisOptions}
            />
          </Paper>
        )}
        {error && (
          <Typography color={'error'} textAlign={'center'}>
            {error}
          </Typography>
        )}
      </Stack>
    </Page>
  );
};
