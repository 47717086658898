import React from 'react';
import { CustomEvent } from './WeekdayHoursPicker';
import fillerWords from '../../statics/fillerWords.json';
import { Stack, TextField } from '@mui/material';
import { Keyword } from './Keyword';

const SPACE_KEYCODE = 32;

type TagsPickerProps = {
  id: string;
  name: string;
  value: string[];
  label: string;
  onChange: (e: CustomEvent) => void;
};
export const TagsPicker = (props: TagsPickerProps) => {
  const { id, name, label, value, onChange } = props;
  const [text, setText] = React.useState<string>('');

  React.useEffect(() => {
    if (text.length > 0 && text.trim().length <= 0) setText('');
  }, [text]);

  const handleKeyPress = (e: any) => {
    if (e.keyCode === SPACE_KEYCODE) addTag();
  };

  const addTag = () => {
    const input = text.trim();
    if (input.length <= 0) return;
    const words = input.split(' ');

    const tagsToAdd = removeFillerWords(words).filter((tag) => {
      return !value.includes(tag);
    });

    const newTags = [...value, ...tagsToAdd];
    setText('');
    onChange({
      type: 'change',
      target: {
        id,
        name,
        value: newTags,
      },
    });
  };

  const removeFillerWords = (words: string[]): string[] => {
    return words.filter((word) => !fillerWords.fillerWords.includes(word.toLowerCase()));
  };

  const removeTag = (tag: string) => {
    const index = value.indexOf(tag);
    const newTags = [...value];
    if (index < 0) return;
    newTags.splice(index, 1);
    onChange({
      type: 'change',
      target: {
        id,
        name,
        value: newTags,
      },
    });
    setText('');
  };

  return (
    <>
      <TextField
        fullWidth
        id="keywordInput"
        name="keywordInput"
        value={text}
        label={label}
        onChange={(e) => setText(e.target.value)}
        onKeyDown={handleKeyPress}
        onBlur={addTag}
      />
      <Stack direction="row" flexWrap={'wrap'} gap={'1rem'}>
        {value.map((tag) => {
          return <Keyword key={tag} name={tag} onDelete={() => removeTag(tag)} />;
        })}
      </Stack>
    </>
  );
};
