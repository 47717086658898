import React from 'react';
import { Stack } from '@mui/material';
import Dropdown from '../inputs/Dropdown';
import DatePicker from '../inputs/DatePicker';
import { isValidBirthday } from '../../validation';
import { TStandardForm } from '../../shared/standards';

export const PRONOUNS = ['He/Him', 'She/Her', 'They/Them', 'Other'];

type StandardProps = {
  value: TStandardForm | undefined;
  onChange: (data: TStandardForm) => void;
};
export const StandardRegisterForm = ({ value, onChange }: StandardProps) => {
  const [pronouns, setPronouns] = React.useState<string>(value?.pronouns || PRONOUNS[0]);
  const [birthday, setBirthday] = React.useState<number>(value?.birthday || Date.now());
  const [birthdayError, setBirthdayError] = React.useState<string>();

  React.useEffect(() => {
    onChange({
      pronouns,
      birthday,
    });
  }, [pronouns, birthday]);

  const handleBirthdayChange = (date: Date | null) => {
    if (!date) {
      setBirthdayError('Must provide a valid birthday to register.');
      return;
    }
    if (!isValidBirthday(date.getTime())) {
      setBirthdayError('Must be 18 or older to register.');
      return;
    }
    setBirthday(date.getTime());
    setBirthdayError(undefined);
  };

  return (
    <Stack spacing={'1rem'}>
      <Dropdown
        required
        label={'PRONOUNS'}
        value={pronouns}
        options={PRONOUNS}
        onChange={(s) => setPronouns(s)}
      />
      <DatePicker
        required
        value={birthday ? new Date(birthday) : undefined}
        label="DATE OF BIRTH"
        error={birthdayError}
        onChange={handleBirthdayChange}
      />
    </Stack>
  );
};
