import { useMutation, useQueryClient } from 'react-query';
import { createVendor } from '../api/vendors';
import { TVendorForm } from '../shared/vendors';

export const useCreateVendorMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: TVendorForm) => createVendor(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getVendors'] });
    },
  });
};
