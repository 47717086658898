import React from 'react';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { Page } from '../../components/layout/page';
import { useParams } from 'react-router-dom';
import { Message, MessagesList } from '../../components/MessagesList';
import { useEventsAPI } from '../../hooks/useEventsAPI';
import TextField from '../../components/inputs/TextField';
import Container from '../../components/Container';
import { getNetworkErrorMessage } from '../../validation';
import {
  DELETE_MESSAGE_SUCCESS,
  POST_MESSAGE_SUCCESS,
  UPDATE_MESSAGE_SUCCESS,
  useToast,
} from '../../hooks/useToast';
import { LoadingText } from '../../components/Loading';
import { useAuth } from '../../auth/useAuth';
import { useGetEventByIdQuery } from '../../queries/useGetEventByIdQuery';

export const MessagesPage = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const { showToast } = useToast();
  const { isLoading: isLoadingEvent, data: event, error: eventError } = useGetEventByIdQuery(id);
  const { fetchMessagesForEvent, fetchPostMessageToEvent, fetchUpdateMessage, fetchDeleteMessage } =
    useEventsAPI();
  const [messages, setMessages] = React.useState<Message[]>([]);
  const [text, setText] = React.useState<string>();
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<string>();

  React.useEffect(() => {
    loadMessages();
  }, []);

  if (!id || !user || isLoadingEvent || !event) return <LoadingText />;

  const loadMessages = async () => {
    try {
      setIsLoading(true);
      const ms = await fetchMessagesForEvent(id);
      setMessages(ms);
    } catch (err) {
      const message = getNetworkErrorMessage(err);
      message && setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePostMessage = async () => {
    if (!text || text.trim().length <= 0) return;
    try {
      setIsLoading(true);
      const newMessages = await fetchPostMessageToEvent(id, text, user._id);
      showToast(POST_MESSAGE_SUCCESS, 'success');
      setText('');
      setMessages(newMessages);
    } catch (err) {
      const message = getNetworkErrorMessage(err);
      message && setError(message);
      message && showToast(message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (message: Message) => {
    try {
      setIsLoading(true);
      const ms = await fetchUpdateMessage(id, message);
      showToast(UPDATE_MESSAGE_SUCCESS, 'success');
      setMessages(ms);
    } catch (err) {
      const message = getNetworkErrorMessage(err);
      message && setError(message);
      message && showToast(message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (message: Message) => {
    try {
      setIsLoading(true);
      const ms = await fetchDeleteMessage(id, message._id);
      showToast(DELETE_MESSAGE_SUCCESS, 'success');
      setMessages(ms);
    } catch (err) {
      const message = getNetworkErrorMessage(err);
      message && setError(message);
      message && showToast(message, 'error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page header={'MESSAGES'}>
      <Stack alignItems={'center'} spacing={'1rem'}>
        {!isLoading && (
          <>
            <MessagesList messages={messages} onDelete={handleDelete} onUpdate={handleUpdate} />
            {user._id === event?.vendorId && (
              <Container maxWidth="300px">
                <Stack spacing={'1rem'}>
                  <Divider flexItem />
                  <TextField placeholder="Message" value={text} multiline onChange={setText} />
                  <Button
                    disabled={!text || text.trim().length <= 0 || isLoading}
                    variant="contained"
                    onClick={handlePostMessage}
                  >
                    Post Message
                  </Button>
                </Stack>
              </Container>
            )}
          </>
        )}
      </Stack>
      {error && (
        <Typography color={'error'} textAlign={'center'}>
          {error}
        </Typography>
      )}
    </Page>
  );
};
