import React from 'react';
import { EventAvailableOutlinedIcon, EventBusyOutlinedIcon } from '.';
import Icon from './Icon';
import { useTheme } from '@mui/material';

export type Props = {
  isToggled?: boolean;
  onClick?: () => void;
  disabled?: boolean;
};
export const Calendar: React.FC<Props> = ({ isToggled, disabled, onClick }) => {
  const theme = useTheme();
  const color = isToggled ? theme.palette.warning.main : theme.palette.primary.main;
  const text = isToggled ? 'Remove from calendar' : 'Add to calendar';
  const displayColor = disabled ? 'lightgrey' : color;
  return (
    <Icon disabled={disabled} tooltip={text} onClick={onClick}>
      {!isToggled ? (
        <EventAvailableOutlinedIcon style={{ color: displayColor }} fontSize="large" />
      ) : (
        <EventBusyOutlinedIcon style={{ color: displayColor }} fontSize="large" />
      )}
    </Icon>
  );
};
