import { Page } from '../../components/layout/page';
import { Search } from '../../components/Search';

export const SearchPage = () => {
  return (
    <Page header='SEARCH'>
      <Search />
    </Page>
  );
};
