import { Dialog } from '@mui/material';
import React from 'react';

type DialogContextData = {
    isOpen: boolean;
    setOpen: (isOpen: boolean, element?: React.ReactNode) => void;
}

const DialogContext = React.createContext<DialogContextData | null>(null);

export const DialogProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [isOpen, setIsOpen] = React.useState<boolean>(false);
    const [element, setElement] = React.useState<React.ReactNode>(<></>);

    const setOpen = (isOpen: boolean, element: React.ReactNode) => {
        setIsOpen(isOpen);
        setElement(element);
    }

    const value = {
        isOpen,
        setOpen,
    };

    return (
        <DialogContext.Provider value={value}>
            {children}
            <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
                {element}
            </Dialog>
        </DialogContext.Provider>
    )
}

export const useDialog = () => {
    const context = React.useContext(DialogContext);
    if (!context) throw new Error('Must use useDialog within a DialogProvider')
    return context;
}