import { useMutation, useQueryClient } from 'react-query';
import { blockUserById } from '../api/users';

type BlockUserOptions = {
  userId: string;
};
export const useBlockUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ userId }: BlockUserOptions) => blockUserById(userId),
    onSuccess: (user) => {
      queryClient.invalidateQueries({ queryKey: ['getVendors', 'getVendorById'] });
    },
  });
};
