import React from 'react';
import { IconButton, Tooltip } from '@mui/material';

type Props = {
  tooltip?: string;
  onClick?: () => void;
  children: any;
  disabled?: boolean;
};
const Icon: React.FC<Props> = ({ tooltip, disabled, children, onClick }) => {
  const isDisabled = disabled !== undefined
    ? disabled : (onClick ? false : true);
  return (
    <Tooltip title={tooltip} onClick={onClick}>
      <IconButton
        disabled={isDisabled}>
        {children}
      </IconButton>
    </Tooltip>
  );
};

export default Icon;
