import { Stack } from '@mui/material';
import { Like } from './icons/Like';
import { Stats } from './icons/Stats';
import { Calendar } from './icons/Calendar';
import { Edit } from './icons/Edit';
import { Share } from './icons/Share';
import { Block } from './icons/Block';
import { Delete } from './icons/Delete';
import { Message } from './icons/Message';

// TODO - API for blocked, needs to return the new event.
// Set the event in state (or re-fetch using useQuery)

type ActionButtonsProps = {
  isLoadingLike?: boolean;
  isLoadingCalendar?: boolean;
  isLoadingBlock?: boolean;
  isLoadingDelete?: boolean;
  isLoadingFollowVendor?: boolean;
  isBlocked?: boolean;
  isOnCalendar?: boolean;
  isLiked?: boolean;
  isFollowing?: boolean;
  onLikeClick?: () => void;
  onFollowClick?: () => void;
  onCalendarClick?: () => void;
  onMessageClick?: () => void;
  onEditClick?: () => void;
  onStatisticsClick?: () => void;
  onDeleteClick?: () => void;
  onShareClick?: () => void;
  onBlockClick?: () => void;
};
export const ActionButtons: React.FC<ActionButtonsProps> = (props) => {
  const {
    isLoadingLike,
    isLoadingBlock,
    isLoadingCalendar,
    isLoadingDelete,
    isLoadingFollowVendor,
    isBlocked,
    isOnCalendar,
    isLiked,
    isFollowing,
    onLikeClick,
    onFollowClick,
    onCalendarClick,
    onEditClick,
    onStatisticsClick,
    onMessageClick,
    onDeleteClick,
    onShareClick,
    onBlockClick,
  } = props;
  return (
    <Stack
      direction={'row'}
      flexWrap={'wrap'}
      justifyContent={'space-evenly'}
      alignItems={'center'}
      height={'100%'}
    >
      {onCalendarClick && (
        <Calendar disabled={isLoadingCalendar} isToggled={isOnCalendar} onClick={onCalendarClick} />
      )}
      {onLikeClick && <Like disabled={isLoadingLike} isToggled={isLiked} onClick={onLikeClick} />}
      {onFollowClick && (
        <Like disabled={isLoadingFollowVendor} isToggled={isFollowing} onClick={onFollowClick} />
      )}
      {onEditClick && <Edit onClick={onEditClick} />}
      {onStatisticsClick && <Stats onClick={onStatisticsClick} />}
      {onMessageClick && <Message onClick={onMessageClick} />}
      {onShareClick && <Share onClick={onShareClick} />}
      {onDeleteClick && <Delete disabled={isLoadingDelete} onClick={onDeleteClick} />}
      {onBlockClick && (
        <Block disabled={isLoadingBlock} isToggled={isBlocked} onClick={onBlockClick} />
      )}
    </Stack>
  );
};
