import { styled } from 'styled-components';
import SLOM from '../../assets/SLOM_White_Trimmed.png';
import { Colors } from '../../theme/colors';
import { Link as LinkDOM, useNavigate } from 'react-router-dom';
import { PALETTE } from '../../theme/palette';
import { Notification } from '../icons/Notification';
import { useAuth } from '../../auth/useAuth';
import { isStandardUser, isVendorUser, isAdminUser } from '../../utils/userUtils';

const HEADER_SMALL = 840;

const Layout = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 5px 3px ${PALETTE.primary.main};

  @media (max-width: ${HEADER_SMALL}px) {
    flex-direction: column-reverse;
  }
`;
const Top = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  transform: translate(0, -20%);
`;
const LinksLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  gap: 3rem;
  padding: 3rem 1rem 1rem 1rem;
  border-bottom: 1px solid ${Colors.primary};

  @media (max-width: ${HEADER_SMALL}px) {
    justify-content: center;
    padding: 1rem;
    margin-top: 2rem;
  }
`;
const LinksRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 3rem;
  padding: 3rem 3rem 1rem 1rem;
  border-bottom: 1px solid ${Colors.primary};

  @media (max-width: ${HEADER_SMALL}px) {
    justify-content: center;
    padding: 1rem;
    margin-top: 2rem;
  }
`;
const LinkBotomLeft = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-start;
  gap: 3rem;
  padding: 1rem 3rem 1rem 1rem;

  @media (max-width: ${HEADER_SMALL}px) {
    justify-content: center;
    padding: 1rem;
  }
`;
const LinkBotomRight = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  gap: 3rem;
  padding: 1rem 3rem 1rem 1rem;

  @media (max-width: ${HEADER_SMALL}px) {
    justify-content: center;
    padding: 1rem;
  }
`;
export const Logo = styled.img`
  width: 150px;
  height: 150px;
  transform: rotate(-90deg);
  cursor: pointer;
`;
export const Link = styled(LinkDOM)`
  color: ${Colors.primary};
  font-size: 24px;
  text-decoration: none;
`;

const Header = () => {
  const navigate = useNavigate();
  const { user, signIn, signOut, isAuthenticated } = useAuth();

  return (
    <Layout>
      <Top>
        <LinksLeft></LinksLeft>
        <LinkBotomLeft></LinkBotomLeft>
      </Top>
      <Logo src={SLOM} alt="SLOM Logo" onClick={() => navigate('/')} />
      <Top>
        <LinksRight>{!isAuthenticated && <Link to="join">BECOME A MEMBER</Link>}</LinksRight>
        <LinkBotomRight></LinkBotomRight>
      </Top>
    </Layout>
  );
};

export default Header;
