import React from 'react';
import { useEventsAPI } from '../hooks/useEventsAPI';
import { useVendorsAPI } from '../hooks/useVendorsAPI';

type LocalData = {
  savedEvents: string[];
};

const DEFAULT_LOCAL_DATA: LocalData = {
  savedEvents: [],
};

type PublicUserContextData = {
    savedEvents: string[];
    publicIsLikedEvent: (eventId: string) => boolean;
    publicLikeEvent: (eventId: string) => void;
    publicUnlikeEvent: (eventId: string) => void;
    publicToggleLikeEvent: (eventId: string) => void;
  };
  
const PublicUserContext = React.createContext<PublicUserContextData | null>(null);

export const PublicUserProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // const { fetchEventLiked } = useEventsAPI();
  const [savedEvents, setSavedEvents] = React.useState<string[]>([]);

  React.useEffect(() => {
    const savedEvents = getSavedEvents();
    setSavedEvents(savedEvents);
  }, []);

  const getSavedEvents = () => {
    return loadLocalData().savedEvents;
  };

  const loadLocalData = () => {
    const localString = localStorage.getItem('SLOM_EVENTS');
    return JSON.parse(localString || JSON.stringify(DEFAULT_LOCAL_DATA)) as LocalData;
  };

  const saveLocalData = (localEvents: LocalData) => {
    localStorage.setItem('SLOM_EVENTS', JSON.stringify(localEvents));
  };

  const publicLikeEvent = (eventId: string) => {
    if (savedEvents.includes(eventId)) return;
    const events = [...savedEvents, eventId];
    setSavedEvents(events);
    saveLocalData({
      savedEvents: events,
    });
    // fetchEventLiked(eventId);
  };

  const publicUnlikeEvent = (eventId: string) => {
    const events = savedEvents.filter((item) => {
      return item !== eventId;
    });
    setSavedEvents(events);
    saveLocalData({
      savedEvents: events,
    });
  };

  const publicIsLikedEvent = (eventId: string) => {
    return savedEvents.includes(eventId);
  };

  const publicToggleLikeEvent = (eventId: string) => {
    if (publicIsLikedEvent(eventId)) {
      publicUnlikeEvent(eventId);
    } else {
      publicLikeEvent(eventId);
    }
  };

  const value = {
    savedEvents,
    publicIsLikedEvent,
    publicLikeEvent,
    publicUnlikeEvent,
    publicToggleLikeEvent,
  };

  return <PublicUserContext.Provider value={value}>{children}</PublicUserContext.Provider>;
};

export const usePublicUser = () => {
  const context = React.useContext(PublicUserContext);
  if (!context) throw new Error('Must have usePublicUser within a PublicUserProvider');
  return context;
};
