import { createTheme } from '@mui/material/styles';
import { PALETTE } from './palette';

const normal = createTheme({
  palette: PALETTE,
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          padding: "10px !important"
        }
      }
    }
  }
});

export default normal;
