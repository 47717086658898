import { Paper, Stack, Divider } from "@mui/material";
import { Copy } from "../icons/Copy";
import { COPY_SUCCESS, useToast } from "../../hooks/useToast";
import { Text } from "../text";

export const CopyDialogContents = () => {
    const { showToast } = useToast();
    return (
        <Paper elevation={5} style={{ padding: '1rem' }}>
            <Stack direction={'row'} alignItems={'center'} justifyContent={'center'}>
                <Text>Copy Link</Text>
                <Copy onClick={() => {
                    navigator.clipboard.writeText(window.location.href);
                    showToast(COPY_SUCCESS, 'success');
                }} />
            </Stack>
            <br />
            <Divider />
            <br />
            <Text textAlign='center'>{window.location.href}</Text>
        </Paper>
    )
}
