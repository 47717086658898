export type Address = {
  venue?: string;
  street: string;
  city: string;
  state: string;
  zipcode: string;
};
export type DayHours = {
  day: DayType;
  hours: Hours;
};

export type Hours = {
  open: number;
  close: number;
};

export type HoursSpan = {
  from: number;
  to: number;
};

export type DayType = keyof typeof DAY_VALUES;

export const REPEAT_OPTIONS_MAP = {
  'Does not repeat': 'Does Not Repeat',
  'Repeat Daily': 'Repeat Daily',
  'Repeat Weekly': 'Repeat Weekly',
};

export type RepeatType = keyof typeof REPEAT_OPTIONS_MAP;

export type DateTime = {
  type: RepeatType;
  start: number;
  end: number;
  finalDay: number;
  days?: Day[];
};

export type Day = keyof typeof DAYS;

export const DAYS = {
  M: 'Monday',
  Tu: 'Tuesday',
  W: 'Wednesday',
  Th: 'Thursday',
  F: 'Friday',
  Sa: 'Saturday',
  Su: 'Sunday',
};

export const DAYS_OF_WEEK = ['M', 'Tu', 'W', 'Th', 'F', 'Sa', 'Su'];

type DayValues = {
  [day: string]: number;
};
export const DAY_VALUES = DAYS_OF_WEEK.reduce<DayValues>((acc, day, i) => {
  acc[day] = i;
  return acc;
}, {});

type Free = {
  type: 'Free';
};
type Single = {
  type: 'Single';
  min: number;
};
type Range = {
  type: 'Range';
  min: number;
  max: number;
};

export type Price = Free | Single | Range;
