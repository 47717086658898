import { User as Auth0User } from '@auth0/auth0-react';
import { TStandard } from '../shared/standards';
import { TVendor } from '../shared/vendors';
import { TAdmin } from '../types/users/admin';
import { TUnsubscribed } from '../types/users/unsubscribed';
import { TUser } from '../auth/useAuth';

export const isStandardUser = (user?: TUser) => {
  return user?.subscription === 'standard';
};

export const isVendorUser = (user?: TUser) => {
  return user?.subscription === 'vendor';
};

export const isAdminUser = (user?: TUser) => {
  return user?.subscription === 'admin';
};

export const isLikedEvent = (eventId: string, user?: TUser): boolean => {
  if (user && isStandardUser()) {
    return false; // user.likedEvents.includes(eventId);
  }
  return false;
};

export const isEventOnCalendar = (eventId: string, user?: TUser): boolean => {
  if (user && isStandardUser()) {
    return false; // user.calendarEvents.includes(eventId);
  }
  return false;
};

export const isFollowingVendor = (vendorId: string, user?: TUser): boolean => {
  if (user && isStandardUser()) {
    return false; // user.followedVendors.includes(vendorId);
  }
  return false;
};

export const getStandardUser = (auth0User: Auth0User): TStandard => {
  const { app_metadata, user_metadata, email, sub, user_id } = auth0User;
  const { subscription, acceptedTermsAndConditions, SLOM_isBlocked } = app_metadata;
  if (subscription !== 'standard') throw new Error('User is not a standard');

  const { pronouns, birthday, likedEvents, calendarEvents, followedVendors } = user_metadata;

  if (!email) throw new Error('Muse have a valid email property');
  if (!sub && !user_id) throw new Error('Muse have a valid sub or user_id property');

  return {
    // sub || user_id should always be the same value.
    _id: sub || user_id,
    email,
    pronouns,
    birthday,
    likedEvents,
    calendarEvents,
    followedVendors,
    subscription: 'standard',
    acceptedTermsAndConditions,
    blocked: SLOM_isBlocked,
  };
};

export const getVendorUser = (auth0User: Auth0User): TVendor => {
  const { app_metadata, user_metadata, email, picture, sub, user_id } = auth0User;
  const { subscription, acceptedTermsAndConditions, SLOM_isBlocked, claimed } = app_metadata;
  if (subscription !== 'vendor') throw new Error('User is not a vendor');
  const {
    companyName,
    address,
    missionStatement,
    businessDescription,
    phoneNumber,
    websiteLink,
    daysOfOperation,
  } = user_metadata;

  if (!email) throw new Error('Muse have a valid email property');
  if (!sub && !user_id) throw new Error('Muse have a valid sub or user_id property');

  return {
    _id: sub || user_id,
    email,
    companyName,
    address,
    missionStatement,
    businessDescription,
    phoneNumber,
    websiteLink,
    daysOfOperation,
    photo: picture,
    blocked: SLOM_isBlocked,
    claimed,
    subscription: 'vendor',
    acceptedTermsAndConditions,
  };
};

export const getAdminUser = (auth0User: Auth0User): TAdmin => {
  const { sub, user_id } = auth0User;

  if (!sub) throw new Error('Muse have a valid sub property');

  return {
    _id: sub || user_id,
    subscription: 'admin',
  };
};

export const getUnsubscribedUser = (auth0User: Auth0User): TUnsubscribed => {
  const { sub, user_id } = auth0User;

  if (!sub && !user_id) throw new Error('Muse have a valid sub or user_id property');

  return {
    _id: sub || user_id,
    subscription: 'unsubscribed',
  };
};
