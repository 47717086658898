import { Address, Price } from '../shared/misc';

export const formatPhoneNumber = (phoneNumber: string): string => {
  switch (phoneNumber.length) {
    case 7: // 8283080
      return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
    case 10: // 7608283080
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
    case 11: // 17608283080
      return `+${phoneNumber.slice(0, 1)} (${phoneNumber.slice(
        1,
        4,
      )}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7)}`;
    default:
      return phoneNumber;
  }
};

export const formatPrice = (price: Price): string => {
  const { type } = price;
  switch (type) {
    case 'Free':
      return 'Free';
    case 'Single': {
      const { min } = price;
      return `$${min}`;
    }
    case 'Range': {
      const { min, max } = price;
      return `$${min} to $${max}`;
    }
    default:
      return 'Free';
  }
};

export const formatAddress = (address: Address) => {
  return `${address.street}, ${address.city}, ${address.state}`;
};

export const formatWebsiteLink = (website: string) => {
  if (website.includes('http://') || website.includes('https://')) return website;
  else return `https://${website}`;
};
