import { useAuth0 } from '@auth0/auth0-react';
import React, { PropsWithChildren } from 'react';
import { TSubscriptionOptions, subscribe } from '../api/users';
import { TStandard } from '../shared/standards';
import { TVendor } from '../shared/vendors';
import { TAdmin } from '../types/users/admin';
import { TUnsubscribed } from '../types/users/unsubscribed';
import {
  getAdminUser,
  getStandardUser,
  getUnsubscribedUser,
  getVendorUser,
} from '../utils/userUtils';

export type TUser = TUnsubscribed | TStandard | TVendor | TAdmin;

type AuthContextData = {
  user?: TUser;
  isLoading: boolean;
  isAuthenticated: boolean;
  signIn: () => void;
  signOut: () => void;
  setUser: (user: TUser) => void;
  subscribe: (user: TUser, data: TSubscriptionOptions) => Promise<Response>;
  error?: Error;
};

const AuthContext = React.createContext<AuthContextData | null>(null);

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    user: auth0User,
    error,
    isLoading,
    isAuthenticated,
    loginWithRedirect,
    logout,
  } = useAuth0();
  const [user, setUser] = React.useState<TUser>();

  React.useEffect(() => {
    if (!auth0User) return;
    const { subscription } = auth0User.app_metadata;
    switch (subscription) {
      case 'standard': {
        const u = getStandardUser(auth0User);
        setUser(u);
        break;
      }
      case 'vendor': {
        const u = getVendorUser(auth0User);
        setUser(u);
        break;
      }
      case 'admin': {
        const u = getAdminUser(auth0User);
        setUser(u);
        break;
      }
      default: {
        const u = getUnsubscribedUser(auth0User);
        setUser(u);
        break;
      }
    }
  }, [auth0User]);

  const signIn = () => {
    loginWithRedirect();
  };

  const signOut = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  const value = {
    user,
    isLoading,
    isAuthenticated,
    signIn,
    signOut,
    setUser,
    subscribe,
    error,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);
  if (!context) throw new Error('Must use useAuth within an AuthProvider');
  return context;
};
