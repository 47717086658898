import React from 'react';
import { Page } from '../../components/layout/page';
import { CalendarView } from '../../components/CalendarView';
import { useEventsAPI } from '../../hooks/useEventsAPI';
import { Text } from '../../components/text';
import { Typography, useTheme } from '@mui/material';
import { getNetworkErrorMessage } from '../../validation';
import { LoadingText } from '../../components/Loading';
import { useAuth } from '../../auth/useAuth';
import { isStandardUser, isEventOnCalendar } from '../../utils/userUtils';
import { TEvent } from '../../shared/events';
import { useGetEventsQuery } from '../../queries/useGetEventsQuery';

export const CalendarPage = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const { isLoading, data: events, error } = useGetEventsQuery();
  const [filteredEvents, setFilteredEvents] = React.useState<TEvent[]>([]);

  React.useEffect(() => {
    if (!user) return;
    // if (isStandardUser(user)) filterForStandards();
  }, [events, user]);

  const filterForStandards = () => {
    // const rsvps = events.filter((event) => isEventOnCalendar(event._id, user));
    // setFilteredEvents(rsvps);
  };

  return (
    <Page header={'My Calendar'} maxWidth="800px">
      {isLoading && <LoadingText />}
      {!isLoading && (
        <>
          {filteredEvents.length > 0 ? (
            <CalendarView events={filteredEvents} />
          ) : (
            <Text textAlign="center" color={theme.palette.secondary.main}>
              Once you add events to your calendar or like them, they will show up here.
            </Text>
          )}
        </>
      )}
      {!!error && (
        <Typography color={'error'} textAlign={'center'}>
          {error as string}
        </Typography>
      )}
    </Page>
  );
};
