import { Checkbox, Stack } from '@mui/material';
import { Text } from './text';

type TermsAndConditionsCheckboxProps = {
  accepted: boolean;
  setAccepted: (accepted: boolean) => void;
};
export const TermsAndConditionsCheckbox = (props: TermsAndConditionsCheckboxProps) => {
  const { accepted, setAccepted } = props;
  return (
    <Stack direction={'row'} alignItems={'center'}>
      <Checkbox
        checked={accepted}
        onChange={(value) => {
          setAccepted(value.target.checked);
        }}
      />
      <Stack direction={'row'} spacing={'5px'} alignItems={'center'}>
        <Text>{'I accept our'}</Text>
        <a target="_blank" href="www.google.com" rel="noreferrer noopener">
          Terms and Conditions
        </a>
      </Stack>
    </Stack>
  );
};
