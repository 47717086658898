import axios, { AxiosResponse } from 'axios';

type Response = AxiosResponse<any, any>;

const getHeaders = (token: string | undefined) => {
  return {
    headers: {
      'Content-Type': 'application/json',
      'authorization': token,
    },
  };
};

export const get = async (url: string, token?: string | undefined): Promise<Response> => {
  return await axios.get(url, getHeaders(token));
};

export const post = async (
  url: string,
  data: any,
  token?: string | undefined,
): Promise<Response> => {
  return await axios.post(url, data, getHeaders(token));
};

export const patch = async (
  url: string,
  token: string | undefined,
  data: any,
): Promise<Response> => {
  return await axios.patch(url, data, getHeaders(token));
};

export const del = async (url: string, token: string | undefined): Promise<Response> => {
  return await axios.delete(url, getHeaders(token));
};
