import { useMutation } from 'react-query';
import { unfollowVendor } from '../api/standards';

type UnfollowVendorOptions = {
  userId: string;
  vendorId: string;
};
export const useUnfollowVendorMutation = () => {
  return useMutation({
    mutationFn: ({ userId, vendorId }: UnfollowVendorOptions) => unfollowVendor(userId, vendorId),
  });
};
