import { useMutation, useQueryClient } from 'react-query';
import { blockEventById } from '../api/events';

type BlockEventOptions = {
  eventId: string;
};
export const useBlockEventMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ eventId }: BlockEventOptions) => blockEventById(eventId),
    onSuccess: (event) => {
      queryClient.invalidateQueries({ queryKey: ['getEventById', event._id] });
    },
  });
};
