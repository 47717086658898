import { useMutation, useQueryClient } from 'react-query';
import { unblockUserById } from '../api/users';

type UnblockUserOptions = {
  userId: string;
};
export const useUnblockUserMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ userId }: UnblockUserOptions) => unblockUserById(userId),
    onSuccess: (user) => {
      queryClient.invalidateQueries({ queryKey: ['getVendors', 'getVendorById', user._id] });
    },
  });
};
