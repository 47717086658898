import { Stack } from '@mui/material';
import React, { useState } from 'react';
import { Text } from '../text';
import { Day as DayComponent } from '../Day';
import { DAYS_OF_WEEK } from '../../shared/constants';
import { Day } from '../../shared/misc';

type Props = {
  // Mandatory
  onChange: (days: Day[]) => void;

  // Optional
  value?: string[];
  label?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
};
const WeekdayPicker = ({ value, label, error, onChange, required, disabled }: Props) => {
  const star = required ? '*' : '';
  const labelText = `${label}` + star;

  const [days, setDays] = useState<string[]>(value || []);

  React.useEffect(() => {
    onChange(days as Day[]);
  }, [days]);

  const handleToggleDay = (day: string) => {
    let newDays = [...days];
    if (newDays.includes(day)) {
      newDays = newDays.filter((d) => d !== day);
    } else {
      newDays.push(day);
    }
    setDays(newDays);
  };

  return (
    <Stack>
      {label && <Text color={disabled ? 'lightgrey' : undefined}>{labelText}</Text>}
      <Stack
        direction={'row'}
        spacing={'5px'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {DAYS_OF_WEEK.map((day, i) => (
          <DayComponent
            key={day}
            day={day}
            selected={days.includes(day)}
            onClick={() => handleToggleDay(day)}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default WeekdayPicker;
