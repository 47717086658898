import { Stack } from '@mui/material';
import DatePicker from '../DatePicker';
import { useEffect, useState } from 'react';
import { DateTimePicker } from '../DateTimePicker';
import { DailyRepeat } from './types';
import { DateTime } from '../../../shared/misc';

type Props = {
  value?: DailyRepeat;
  onChange: (data: DateTime) => void;
};
export const DailyRepeatPicker = ({ value, onChange }: Props) => {
  const [start, setStart] = useState<number>(value?.start || Date.now());
  const [end, setEnd] = useState<number>(value?.end || Date.now());
  const [finalDay, setFinalDay] = useState<number>(value?.finalDay || Date.now());

  useEffect(() => {
    if (!start || !end || !finalDay) {
      return;
    }
    onChange({ type: 'Repeat Daily', start, end, finalDay });
  }, [start, end, finalDay]);

  const handleStartChange = (date: Date | null) => {
    if (date) setStart(date.getTime());
  };
  const handleEndChange = (date: Date | null) => {
    if (date) setEnd(date.getTime());
  };
  const handleFinalDayChange = (date: Date | null) => {
    if (date) setFinalDay(date.getTime());
  };

  return (
    <Stack spacing={'1rem'}>
      <DateTimePicker label="Event Start" value={new Date(start)} onChange={handleStartChange} />
      <DateTimePicker label="Event End" value={new Date(end)} onChange={handleEndChange} />
      <DatePicker
        label="Final Repeat Date"
        value={new Date(finalDay)}
        onChange={handleFinalDayChange}
      />
    </Stack>
  );
};
