import { Grid } from '@mui/material';
import DEFAULT_IMAGE from '../assets/Default_Image.png';

type ImageItemProps = {
  alt: string;
  src?: string;
  xs?: number;
};
export const GridImageItem: React.FC<ImageItemProps> = ({ src, alt, xs }) => {
  return (
    <Grid item xs={xs || 3} justifyContent={'center'} alignItems={'center'}>
      <img
        src={src || DEFAULT_IMAGE}
        width={'100%'}
        alt={alt}
        style={{
          objectFit: 'cover',
          borderRadius: '15px',
        }}
      />
    </Grid>
  );
};
