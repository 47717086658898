import { Text } from '../../components/text';
import Container from '../../components/Container';
import { Box, Button, Divider, Grid, List, Stack, useTheme } from '@mui/material';
import { Page } from '../../components/layout/page';
import { Paper } from '../../components/Paper';
import { Checkmark } from '../../components/icons/Checkmark';
import { useAuth } from '../../auth/useAuth';

const standardPoints = [
  'Follow vendors',
  'Customize interest settings',
  'Save events to your calendar',
  'Like events you are interested in',
  'Receive notifications from vendors',
];

const vendorPoints = [
  'Easy advertising',
  'Demographic statistics',
  'Unlimited event posting',
  'Easy way of tracking your events',
  'Users have direct access to your website',
  'Easy way to communicate with your attendees',
];

const SloganItem = () => {
  const theme = useTheme();
  const color = theme.palette.primary.main;
  return (
    <Box>
      <Text textAlign="center" fontSize="32px" fancy>
        Why sign up with Social Life of Mine?
      </Text>
      <Text textAlign="center" color={color}>{`(Because we make life easy!)`}</Text>
    </Box>
  );
};

const StandardItem = () => {
  return (
    <Paper elevation={1} style={{ maxWidth: '468px', height: '100%', padding: '2rem 1rem' }}>
      <Stack height={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack>
          <Text textAlign="center" fontWeight="bold" fontSize="28px">
            Standard Account
          </Text>
          <Text fancy textAlign="center" fontSize="30px">
            (Free to sign up!)
          </Text>
          <Divider flexItem style={{ paddingTop: '1rem' }} />
          <br />
          <Text textAlign="center" fontSize="18px" fontWeight="bold">
            A standard account is for an individual who is looking to find events and vendors in
            their area.
          </Text>
          <List>
            {standardPoints.map((point) => (
              <Stack
                key={point}
                direction="row"
                justifyContent="center"
                alignItems="center"
                paddingRight="35px"
              >
                <Checkmark fontSize="small" />
                <Text
                  key={point}
                  textAlign="center"
                  fontSize="18px"
                  style={{ 'line-height': '25px' }}
                >
                  {point}
                </Text>
              </Stack>
            ))}
          </List>
        </Stack>
        {/* <Stack justifyContent={'center'} alignItems={'center'}>
          <Button variant="contained" onClick={() => signIn()} style={{ width: '300px' }}>
            Create Standard Account
          </Button>
        </Stack> */}
      </Stack>
    </Paper>
  );
};

const VendorItem = () => {
  return (
    <Paper elevation={1} style={{ maxWidth: '468px', height: '100%', padding: '2rem 1rem' }}>
      <Stack height={'100%'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack>
          <Text fontWeight="bold" fontSize="28px" style={{ textAlign: 'center' }}>
            Vendor Account
          </Text>
          <Text fancy textAlign="center" fontSize="30px">
            (Free to sign up!)
          </Text>
          <Divider style={{ paddingTop: '1rem' }} />
          <br />
          <Text textAlign="center" fontSize="18px" fontWeight="bold">
            A vendor account is for a business or person who plans on hosting and promoting events.
          </Text>
          <List>
            {vendorPoints.map((point) => (
              <Stack
                key={point}
                direction="row"
                justifyContent="center"
                alignItems="center"
                paddingRight="35px"
              >
                <Checkmark fontSize="small" />
                <Text
                  key={point}
                  textAlign="center"
                  fontSize="18px"
                  style={{ 'line-height': '25px' }}
                >
                  {point}
                </Text>
              </Stack>
            ))}
          </List>
        </Stack>
        {/* <Stack justifyContent={'center'} alignItems={'center'}>
          <Button variant="contained" onClick={() => signIn()} style={{ width: '300px' }}>
            Create Vendor Account
          </Button>
        </Stack> */}
      </Stack>
    </Paper>
  );
};

export const BecomeAMemberPage = () => {
  return (
    <Page header="BECOME A MEMBER" maxWidth="1200px">
      <Grid container spacing={'3rem'} justifyContent={'center'}>
        <Grid item xs={12}>
          <SloganItem />
        </Grid>
        <Grid item md={12} lg={6} justifyContent={'center'}>
          <Stack height={'100%'} alignItems={'center'}>
            <StandardItem />
          </Stack>
        </Grid>
        <Grid item md={12} lg={6} justifyContent={'center'}>
          <Stack height={'100%'} alignItems={'center'}>
            <VendorItem />
          </Stack>
        </Grid>
        <Grid item xs={12} justifyContent={'center'}>
          <Text textAlign="center">Have any questions? We are here to help.</Text>
          <Text textAlign="center">
            Contact Paige at:{' '}
            <a href="mailto:paige@sociallifeofmine.com">paige@sociallifeofmine.com</a>
          </Text>
        </Grid>
      </Grid>
    </Page>
  );
};
