import { createFetch } from './createFetch';
import { TStandard, TStandardForm } from '../shared/standards';
import { TVendor, TVendorForm } from '../shared/vendors';
import { TUser } from '../auth/useAuth';
import { getStandardUser, getVendorUser } from '../utils/userUtils';

export type Subscription = 'standard' | 'vendor' | 'admin' | 'unsubscribed';
type SubscriptionWithoutAdmin = Omit<Subscription, 'admin'>;

type SubscriptionData = {
  subscription: SubscriptionWithoutAdmin;
  acceptedTermsAndConditions: boolean;
};
type VendorSubscriptionData = TVendorForm & SubscriptionData;
type StandardSubscriptionData = TStandardForm & SubscriptionData;
export type TSubscriptionOptions = VendorSubscriptionData | StandardSubscriptionData;

/**
 * @description This will set user and app metadata within Auth0
 * @param user The authenticated user
 * @param data The subscription data
 * @returns Promise of the network response
 */
export const subscribe = async (user: TUser, data: TSubscriptionOptions) => {
  const body = JSON.stringify(data);
  return await createFetch(`/users/${user._id}/subscribe`, 'POST', body);
};

export const updateUser = async (id: string, data: TStandardForm | TVendorForm, token?: string) => {
  const body = JSON.stringify(data);
  const url = `/users/${id}`;
  const response = await createFetch(url, 'PATCH', body, token);
  const result = await response.json();
  const authUser = result.data;
  const { subscription } = authUser.app_metadata.subscription;
  if (subscription === 'vendor') return getVendorUser(authUser) as TVendor;
  return getStandardUser(authUser) as TStandard;
};

export const blockUserById = async (userId: string) => {
  const response = await createFetch(`/users/${userId}/block`, 'POST', '');
  const result = await response.json();
  return result as TUser;
};

export const unblockUserById = async (userId: string) => {
  const response = await createFetch(`/users/${userId}/block`, 'DELETE');
  const result = await response.json();
  return result as TUser;
};
