import { Stack } from '@mui/material';
import { DateTimePicker as MUIDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import { Text } from '../../components/text';
import { Colors } from '../../theme/colors';

type Props = {
  // Mandatory
  onChange: (date: Date | null) => void;

  // Optional
  value?: Date;
  label?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
};
export const DateTimePicker = ({ value, label, error, onChange, required, disabled }: Props) => {
  const star = required ? '*' : '';
  const labelText = `${label}` + star;

  return (
    <Stack>
      {label && <Text color={disabled ? 'lightgrey' : undefined}>{labelText}</Text>}
        <MUIDateTimePicker
          value={dayjs(value)}
          disabled={disabled}
          onChange={(d) => d && onChange(d.toDate())}
          slotProps={{
            textField: {
              helperText: error,
              error: error !== undefined,
              sx: {
                input: { color: Colors.primary },
                label: { color: Colors.primary },
              },
            },
          }}
        />
    </Stack>
  );
};
