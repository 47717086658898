import { UPDATE_PROFILE_SUCCESS, useToast } from '../../../hooks/useToast';
import { getNetworkErrorMessage } from '../../../validation';
import { TVendor, TVendorForm } from '../../../shared/vendors';
import { useAuth } from '../../../auth/useAuth';
import { useUpdateUserMutation } from '../../../mutations/useUpdateStandardMutation';

export const useUpdateVendor = () => {
  const { setUser } = useAuth();
  const { showToast } = useToast();
  const { mutateAsync, error, isLoading } = useUpdateUserMutation();

  const updateVendor = async (
    vendor: TVendor,
    data: TVendorForm,
    setSubmitting: (isSubmitting: boolean) => void,
  ) => {
    try {
      setSubmitting(true);
      const newUser = await mutateAsync({ id: vendor._id, data });
      showToast(UPDATE_PROFILE_SUCCESS, 'success'); // TODO - This does not show
      setUser(newUser);
    } catch (err) {
      const message = getNetworkErrorMessage(err); // TODO - Redo errors
      console.log(err);
      message && showToast(message, 'error');
    } finally {
      setSubmitting(false);
    }
  };

  return { updateVendor, error, isLoading };
};
