import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, Divider, Stack } from '@mui/material';
import { TextField } from '@mui/material';
import { WeekdayHoursPicker } from '../inputs/WeekdayHoursPicker';
import { TVendorForm } from '../../shared/vendors';
import { TermsAndConditionsCheckbox } from '../TermsAndConditionsCheckbox';

const vendorFormSchema = yup.object({
  companyName: yup.string().required('Please enter your company name.'),
  street: yup.string().required('Required.'),
  city: yup.string().required('Required.'),
  state: yup.string().required('Required.'),
  zipcode: yup.string().required('Required.'),
  missionStatement: yup.string().required('Please enter your company mission statement.'),
  businessDescription: yup.string().required('Please enter your company business description.'),
  phoneNumber: yup.string().required('Please enter your company phone number.'),
  websiteLink: yup.string().required('Please enter your company website.'),
  daysOfOperation: yup.array().required('Please enter your company hours.'),
  photo: yup.string().required('Please add your company logo / photo.'),
});

export type VendorFormProps = {
  submitLabel: string;
  initialValues: TVendorForm;
  onSubmit: (form: TVendorForm, setSubmitting: (submitting: boolean) => void) => Promise<void>;
  showTermsAndConditions?: boolean;
  onCancel?: () => void;
};
export const VendorForm: React.FC<React.PropsWithChildren<VendorFormProps>> = ({
  submitLabel,
  initialValues,
  showTermsAndConditions,
  onSubmit,
  onCancel,
}) => {
  const [accepted, setAccepted] = React.useState(false);

  const formik = useFormik({
    validationSchema: vendorFormSchema,
    initialValues: {
      companyName: initialValues.companyName || '',
      street: initialValues.address?.street || '',
      city: initialValues.address?.city || '',
      state: initialValues.address?.state || '',
      zipcode: initialValues.address?.zipcode || '',
      missionStatement: initialValues.missionStatement || '',
      businessDescription: initialValues.businessDescription || '',
      phoneNumber: initialValues.phoneNumber || '',
      websiteLink: initialValues.websiteLink || '',
      daysOfOperation: initialValues.daysOfOperation || [],
      photo: initialValues.photo || '',
    },
    onSubmit: (values, { setSubmitting }) =>
      onSubmit(
        {
          companyName: values.companyName,
          address: {
            street: values.street,
            city: values.city,
            state: values.state,
            zipcode: values.zipcode,
          },
          missionStatement: values.missionStatement,
          businessDescription: values.businessDescription,
          phoneNumber: values.phoneNumber,
          websiteLink: values.websiteLink,
          daysOfOperation: values.daysOfOperation,
          photo: values.photo,
        },
        setSubmitting,
      ),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack spacing={'1rem'}>
        <TextField
          fullWidth
          id="companyName"
          name="companyName"
          label="Company Name"
          value={formik.values.companyName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.companyName && Boolean(formik.errors.companyName)}
          helperText={formik.touched.companyName && (formik.errors.companyName as string)}
        />
        <TextField
          fullWidth
          id="businessDescription"
          name="businessDescription"
          label="Business Description"
          value={formik.values.businessDescription}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.businessDescription && Boolean(formik.errors.businessDescription)}
          helperText={
            formik.touched.businessDescription && (formik.errors.businessDescription as string)
          }
        />
        <TextField
          fullWidth
          multiline
          inputProps={{
            style: {
              height: '110px',
            },
            autoComplete: 'new-password',
            form: { autoComplete: 'off' },
          }}
          id="missionStatement"
          name="missionStatement"
          label="Mission Statement"
          value={formik.values.missionStatement}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.missionStatement && Boolean(formik.errors.missionStatement)}
          helperText={formik.touched.missionStatement && (formik.errors.missionStatement as string)}
        />
        <Divider flexItem />
        <TextField
          fullWidth
          id="phoneNumber"
          name="phoneNumber"
          label="Phone Number"
          value={formik.values.phoneNumber}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
          helperText={formik.touched.phoneNumber && (formik.errors.phoneNumber as string)}
        />
        <TextField
          fullWidth
          id="websiteLink"
          name="websiteLink"
          label="Website Link"
          value={formik.values.websiteLink}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.websiteLink && Boolean(formik.errors.websiteLink)}
          helperText={formik.touched.websiteLink && (formik.errors.websiteLink as string)}
        />
        <Divider flexItem />
        <TextField
          fullWidth
          id="street"
          name="street"
          label="Street Address"
          value={formik.values.street}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.street && Boolean(formik.errors.street)}
          helperText={formik.touched.street && (formik.errors.street as string)}
        />
        <Stack direction={'row'} spacing={2}>
          <TextField
            fullWidth
            id="city"
            name="city"
            label="City"
            value={formik.values.city}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && (formik.errors.city as string)}
          />
          <TextField
            fullWidth
            id="state"
            name="state"
            label="State"
            value={formik.values.state}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.state && Boolean(formik.errors.state)}
            helperText={formik.touched.state && (formik.errors.state as string)}
          />
          <TextField
            fullWidth
            id="zipcode"
            name="zipcode"
            label="Zipcode"
            value={formik.values.zipcode}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.zipcode && Boolean(formik.errors.zipcode)}
            helperText={formik.touched.zipcode && (formik.errors.zipcode as string)}
          />
        </Stack>
        <Divider flexItem />
        <TextField
          fullWidth
          id="photo"
          name="photo"
          label="Company Logo / Photo"
          value={formik.values.photo}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.photo && Boolean(formik.errors.photo)}
          helperText={formik.touched.photo && (formik.errors.photo as string)}
        />
        <Divider flexItem />
        <WeekdayHoursPicker
          id="daysOfOperation"
          name="daysOfOperation"
          value={formik.values.daysOfOperation}
          onChange={formik.handleChange}
        />
        {showTermsAndConditions && (
          <>
            <Divider flexItem />
            <TermsAndConditionsCheckbox accepted={accepted} setAccepted={setAccepted} />
          </>
        )}
        <Divider flexItem />
        <Stack direction={'row'} justifyContent={'space-between'}>
          {onCancel && (
            <Button color="primary" variant="contained" fullWidth onClick={onCancel}>
              Cancel
            </Button>
          )}
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={formik.isSubmitting || (showTermsAndConditions && !accepted)}
          >
            {submitLabel}
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};
