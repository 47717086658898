import React, { useState } from 'react';
import Container from '../../components/Container';
import Dropdown from '../../components/inputs/Dropdown';
import { Divider, Stack, Typography } from '@mui/material';
import { Page } from '../../components/layout/page';
import { useAuth } from '../../auth/useAuth';
import { Subscription } from '../../api/users';
import { VendorForm } from '../../components/forms/VendorForm';
import { DEFAULT_VENDOR_FORM } from '../../constants/vendor-form';
import { useRegister } from './subscriptionPageHooks';

const SUBSCRIPTIONS: Subscription[] = ['standard', 'vendor'];

export const SubscriptionPage = () => {
  const { signOut } = useAuth();
  const { registerVendor, error } = useRegister();
  const [subscription, setSubscription] = useState<Subscription>('vendor');

  const registerStandard = async () => {};

  return (
    <Page header="Registration">
      <Container maxWidth="400px">
        <Stack spacing={'1rem'}>
          <Dropdown
            label="SUBSCRIPTION"
            value={subscription}
            options={SUBSCRIPTIONS}
            onChange={(s) => setSubscription(s as Subscription)}
          />
          <Divider />
          {subscription === 'vendor' && (
            <VendorForm
              submitLabel={'Complete'}
              initialValues={DEFAULT_VENDOR_FORM}
              onSubmit={registerVendor}
              showTermsAndConditions={true}
              onCancel={signOut}
            />
          )}
          {/* {subscription === 'standard' && (
            <StandardRegisterForm value={standardForm} onChange={setStandardForm} />
          )} */}
        </Stack>
        {error && (
          <Typography color={'error'} textAlign={'center'}>
            {error}
          </Typography>
        )}
      </Container>
    </Page>
  );
};
