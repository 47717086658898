import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CREATE_EVENT_SUCCESS, useToast } from '../../../hooks/useToast';
import { getNetworkErrorMessage } from '../../../validation';
import { useCreateEventMutation } from '../../../mutations/useCreateEventMutation';
import { TEventForm } from '../../../shared/events';

export const useCreateEvent = () => {
  const { showToast } = useToast();
  const navigate = useNavigate();
  const { mutateAsync } = useCreateEventMutation();
  const [error, setError] = React.useState<string>();

  const createEvent = async (data: TEventForm, setIsSubmitting: (bool: boolean) => void) => {
    try {
      setIsSubmitting(true);
      await mutateAsync(data);
      navigate(0);
      showToast(CREATE_EVENT_SUCCESS, 'success'); // TODO - Does not show
    } catch (err) {
      const message = getNetworkErrorMessage(err);
      message && setError(message);
      message && showToast(message, 'error');
    } finally {
      setIsSubmitting(false);
    }
  };

  return { createEvent, error };
};
