import { Stack } from '@mui/material';
import DatePicker from '../DatePicker';
import WeekdayPicker from '../WeekdayPicker';
import React from 'react';
import { DateTimePicker } from '../DateTimePicker';
import { WeeklyRepeat } from './types';
import { DateTime, Day } from '../../../shared/misc';

type Props = {
  value?: WeeklyRepeat;
  onChange: (data: DateTime) => void;
};
export const WeeklyRepeatPicker = ({ value, onChange }: Props) => {
  const [start, setStart] = React.useState<number>(value?.start || Date.now());
  const [end, setEnd] = React.useState<number>(value?.end || Date.now());
  const [finalDay, setFinalDay] = React.useState<number>(value?.finalDay || Date.now());
  const [days, setDays] = React.useState<Day[]>(value?.days || []);

  React.useEffect(() => {
    if (!start || !end || !finalDay || days.length <= 0) {
      return;
    }
    onChange({ type: 'Repeat Weekly', start, end, finalDay, days });
  }, [start, end, finalDay, days]);

  const handleStartChange = (date: Date | null) => {
    if (date) setStart(date.getTime());
  };
  const handleEndChange = (date: Date | null) => {
    if (date) setEnd(date.getTime());
  };
  const handleFinalDayChange = (date: Date | null) => {
    if (date) setFinalDay(date.getTime());
  };

  return (
    <Stack spacing={'1rem'}>
      <DateTimePicker label="Event Start" value={new Date(start)} onChange={handleStartChange} />
      <DateTimePicker label="Event End" value={new Date(end)} onChange={handleEndChange} />
      <DatePicker
        label="Final Repeat Date"
        value={new Date(finalDay)}
        onChange={handleFinalDayChange}
      />
      <WeekdayPicker value={days} label={'Days of the Week'} onChange={setDays} />
    </Stack>
  );
};
