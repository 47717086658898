import { useQuery } from 'react-query';
import { getEventById } from '../api/events';

export const useGetEventByIdQuery = (eventId: string | undefined) => {
  return useQuery({
    queryKey: ['getEventById', eventId],
    queryFn: () => getEventById(eventId),
    enabled: !!eventId,
  });
};
