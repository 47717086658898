import { Divider, Grid, ListItem, Stack, Typography } from '@mui/material';
import Container from '../Container';
import { Text } from '../text';
import DEFAULT_IMAGE from '../../assets/Default_Image.png';
import { formatAddress, formatPhoneNumber } from '../../utils/formatting';
import { Clickable } from '../Clickable';
import { Like } from '../icons/Like';
import { useNavigate } from 'react-router-dom';
import { useStandardsAPI } from '../../hooks/useStandardsAPI';
import { Paper } from '../Paper';
import { getNetworkErrorMessage } from '../../validation';
import React from 'react';
import {
  FOLLOW_VENDOR_SUCCESS,
  REMOVE_FOLLOW_VENDOR_SUCCESS,
  useToast,
} from '../../hooks/useToast';
import { useAuth } from '../../auth/useAuth';
import { isStandardUser, isFollowingVendor } from '../../utils/userUtils';
import { TVendor } from '../../shared/vendors';
import { ClickableGrid } from './ClickableGrid';
import { useActionButtons } from '../../hooks/useActionButtons';
import { ActionButtons } from '../ActionButtons';

type ImageItemProps = {
  src?: string;
  alt: string;
};
const ImageItem: React.FC<ImageItemProps> = ({ src, alt }) => {
  return (
    <Grid item xs={3} justifyContent={'center'} alignItems={'center'}>
      <img
        src={src || DEFAULT_IMAGE}
        width={'100%'}
        alt={alt}
        style={{
          objectFit: 'cover',
          borderRadius: '15px',
        }}
      />
    </Grid>
  );
};

type InfoItemProps = {
  vendor: TVendor;
};
const InfoItem: React.FC<InfoItemProps> = ({ vendor }) => {
  return (
    <Grid item xs={5} padding={'1rem'}>
      <Stack spacing={'1rem'} style={{ width: '100%' }}>
        {vendor.companyName && (
          <Text textAlign="center" fontSize="24px" fontWeight="bold">
            {vendor.companyName}
          </Text>
        )}
        <br />
        {vendor.businessDescription && (
          <Text textAlign="center" fontWeight="bold">
            {vendor.businessDescription}
          </Text>
        )}
        <br />
        {vendor.phoneNumber && (
          <Text textAlign="center">{formatPhoneNumber(vendor.phoneNumber)}</Text>
        )}
        {vendor.websiteLink && <Text textAlign="center">{vendor.websiteLink}</Text>}
        {vendor.address && <Text textAlign="center">{formatAddress(vendor.address)}</Text>}
      </Stack>
    </Grid>
  );
};

type Props = {
  vendor: TVendor;
  onClick: (error: TVendor) => void;
  onError: (error: unknown) => void;
};
export const VendorItem: React.FC<Props> = (props) => {
  const { vendor, onClick, onError } = props;
  const { user } = useAuth();

  const { followVendorToggleMutation, toggleFollowVendorClick } = useActionButtons();
  const { error: followVendorError, isLoading: isLoadingFollowVendor } = followVendorToggleMutation;

  React.useEffect(() => {
    if (followVendorError) onError(followVendorError);
  }, [followVendorError]);

  // TODO - NEW
  const isFollowingVendor = false;
  const isOwner = user?._id === vendor._id;
  const isPublicUser = !user;
  const isStandardUser = user && user.subscription === 'standard';

  const onFollowClick = () => toggleFollowVendorClick(isFollowingVendor, vendor._id);

  return (
    <ListItem disabled={vendor.blocked}>
      <Paper elevation={1} width={'100%'}>
        <ClickableGrid container padding={'1rem 0'} onClick={() => onClick(vendor)}>
          <Grid
            container
            item
            xs={11}
            borderRight={'1px solid lightgrey'}
            width={'100%'}
            justifyContent={'space-evenly'}
          >
            <ImageItem src={vendor.photo} alt={vendor.companyName} />
            <InfoItem vendor={vendor} />
          </Grid>
          <Grid container item xs={1}>
            <ActionButtons
              isFollowing={isFollowingVendor}
              isLoadingFollowVendor={isLoadingFollowVendor}
              onFollowClick={isPublicUser || isStandardUser ? onFollowClick : undefined}
            />
          </Grid>
          {vendor.blocked && (
            <Grid
              container
              item
              xs={12}
              justifyContent={'center'}
              padding={'1rem'}
              marginTop={'1rem'}
              style={{ background: '#0000001c' }}
            >
              <Text textAlign="center">Vendor is blocked</Text>
            </Grid>
          )}
        </ClickableGrid>
      </Paper>
    </ListItem>
  );
};
