import { Button, Stack, Typography, List, ListItem } from '@mui/material';
import Container from '../../components/Container';
import { Page } from '../../components/layout/page';
import { Text } from '../../components/text';
import { useStandardsAPI } from '../../hooks/useStandardsAPI';
import React from 'react';
import { Notification } from '../../types/notifications';
import { Paper } from '../../components/Paper';
import { useAuth } from '../../auth/useAuth';
import { isStandardUser } from '../../utils/userUtils';

export const NotificationsPage = () => {
  const { user } = useAuth();
  const { fetchNotifications } = useStandardsAPI();
  const [notifications, setNotifications] = React.useState<Notification[]>([]);

  React.useEffect(() => {
    loadNotifications();
  }, []);

  const loadNotifications = async () => {
    // if (!user || !isStandardUser(user)) return;
    // const ns = await fetchNotifications(user?.id);
    // setNotifications(ns);
  };

  // if (!isStandardUser(user)) return null;

  return (
    <Page header={'PROFILE'} maxWidth="800px">
      <Container maxWidth="400px">
        <List>
          {notifications.map((notification) => (
            <ListItem key={notification._id}>
              <Paper>
                <Text>{notification.message}</Text>
              </Paper>
            </ListItem>
          ))}
        </List>
      </Container>
    </Page>
  );
};
