import { Page } from '../../components/layout/page';
import { useNavigate, useParams } from 'react-router-dom';
import { Stack, Button, Typography } from '@mui/material';
import { LoadingText } from '../../components/Loading';
import { useAuth } from '../../auth/useAuth';
import { useGetVendorByIdQuery } from '../../queries/useGetVendorByIdQuery';
import { VendorForm } from '../../components/forms/VendorForm';
import { useUpdateVendor } from './editVendorHook';

export const VendorEditPage = () => {
  const { id } = useParams();
  const { user } = useAuth();
  const {
    data: vendor,
    isLoading: isLoadingVendor,
    error: vendorError,
  } = useGetVendorByIdQuery(id);
  const { updateVendor, error } = useUpdateVendor();

  if (!vendor || isLoadingVendor) return <LoadingText />;
  const isOwner = user?._id === vendor._id;
  const label = isOwner ? 'Update Profile' : 'Update Vendor';

  return (
    <Page header={label} maxWidth="400px">
      <Stack spacing={'1rem'} alignItems={'center'}>
        <VendorForm
          submitLabel={label}
          initialValues={vendor}
          onSubmit={(form, setSubmitting) => updateVendor(form, vendor, setSubmitting)}
        />
      </Stack>
    </Page>
  );
};
