import { useMutation } from 'react-query';
import { dislikeEvent } from '../api/standards';

type DislikeEventOptions = {
  userId: string;
  eventId: string;
};
export const useDislikeEventMutation = () => {
  return useMutation({
    mutationFn: ({ userId, eventId }: DislikeEventOptions) => dislikeEvent(userId, eventId),
  });
};
