import { Stack, useTheme } from "@mui/material";
import { Text } from "../../../components/text";

export const Blocked = () => {
    const theme = useTheme();

    return (
        <Stack>
            <Text 
                fontWeight="bold" 
                fontSize="32px" 
                textAlign="center"
                color={theme.palette.secondary.main}>
                Your account is BLOCKED.
            </Text>
            <br />
            <Text  
                textAlign="center"
                color={theme.palette.secondary.main}>
                If you think this is a mistake, please contact Paige at paige@sociallifeofmine.com
            </Text>
        </Stack>
    );
}
