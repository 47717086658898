import React from 'react';
import { EditOutlinedIcon } from '.';
import Icon from './Icon';
import { useTheme } from '@mui/material';

export type Props = {
  fontSize?: 'small' | 'large';
  onClick: () => void;
};
export const Edit: React.FC<Props> = ({ onClick, fontSize }) => {
  const theme = useTheme();
  const color = theme.palette.info.main;
  return (
    <Icon tooltip="Edit" onClick={onClick}>
      <EditOutlinedIcon style={{ color }} fontSize={fontSize || "large"} />
    </Icon>
  );
};
