import { Stack } from '@mui/material';
import { Colors } from '../../theme/colors';
import { DatePicker as MUIDatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { Text } from '../../components/text';

type Props = {
  // Mandatory
  onChange: (date: Date | null) => void;

  // Optional
  value?: Date | null;
  label?: string;
  error?: string;
  required?: boolean;
  disabled?: boolean;
};
const DatePicker = ({ value, label, error, onChange, required, disabled }: Props) => {
  const star = required ? '*' : '';
  const labelText = `${label}` + star;

  return (
    <Stack>
      {label && <Text color={disabled ? 'lightgrey' : undefined}>{labelText}</Text>}
        <MUIDatePicker
          value={value ? dayjs(value) : undefined}
          disabled={disabled}
          onChange={(d: any) => {d ? onChange(d.toDate()) : onChange(null)}}
          slotProps={{
            textField: {
              helperText: error,
              error: error !== undefined,
              sx: {
                input: { color: Colors.primary },
                label: { color: Colors.primary },
              },
            },
          }}
        />
    </Stack>
  );
};

export default DatePicker;
