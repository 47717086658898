import { useMutation, useQueryClient } from 'react-query';
import { TEvent, TEventForm } from '../shared/events';
import { updateEvent } from '../api/events';

type EventUpdateOptions = {
  data: TEventForm;
  eventId: string;
};
export const useUpdateEventMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ data, eventId }: EventUpdateOptions) => updateEvent(data, eventId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['getEventById', 'getEvents'] });
    },
  });
};
