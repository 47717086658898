import { Typography } from '@mui/material';
import React from 'react';

export const ErrorText: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Typography color={'error'} textAlign={'center'}>
      {children}
    </Typography>
  );
};
