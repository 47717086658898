import { TVendorForm } from '../shared/vendors';

export const DEFAULT_VENDOR_FORM: TVendorForm = {
  companyName: '',
  address: {
    street: '',
    city: '',
    state: '',
    zipcode: '',
  },
  missionStatement: '',
  businessDescription: '',
  phoneNumber: '',
  websiteLink: '',
  daysOfOperation: [],
  photo: '',
};
