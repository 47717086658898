import { Checkbox, Divider, FormControlLabel, List, Stack, Typography } from '@mui/material';
import Container from './Container';
import TextField from './inputs/TextField';
import DatePicker from './inputs/DatePicker';
import React from 'react';
import { CalendarView } from './CalendarView';
import { usePublicUser } from '../context/PublicUserProvider';
import Dropdown from './inputs/Dropdown';
import { VendorItem } from './items/VendorItem';
import { Paper } from './Paper';
import { LoadingText } from './Loading';
import { useAuth } from '../auth/useAuth';
import { TVendor } from '../shared/vendors';
import { TEvent } from '../shared/events';
import { useNavigate } from 'react-router-dom';
import { useGetEventsQuery } from '../queries/useGetEventsQuery';
import { useGetVendorsQuery } from '../queries/useGetVendorsQuery';

export const isEventWithinDateRange = (event: TEvent, startDate: Date | null | undefined) => {
  if (!startDate) return true;

  const { dateTime } = event;
  let { type, start } = dateTime;
  const eventStartDate = new Date(start);

  switch (type) {
    case 'Does not repeat':
      return eventStartDate.getTime() >= startDate.getTime();
    case 'Repeat Daily':
      let { finalDay: dailyFinalDay } = dateTime as any;
      if (!dailyFinalDay) return true; // This should never happen
      const finalDayDaily = new Date(dailyFinalDay);
      return finalDayDaily.getTime() >= startDate.getTime();
    case 'Repeat Weekly':
      let { finalDay: weeklyFinalDay } = dateTime as any;
      if (!weeklyFinalDay) return true; // This should never happen
      const finalDayWeekly = new Date(weeklyFinalDay);
      return finalDayWeekly.getTime() >= startDate.getTime();
    default:
      return true;
  }
};

export const isEventWithinPriceRange = (event: TEvent, maxPrice: number | undefined) => {
  const { price } = event;
  const { type } = price;

  if (!maxPrice) return true;

  switch (type) {
    case 'Free':
      return true;
    case 'Single':
      const { min: minSingle } = price;
      const minSinglePrice = minSingle;
      if (!minSinglePrice) return false;
      return parseFloat(`${minSinglePrice}`) <= parseFloat(`${maxPrice}`);
    case 'Range':
      const { min: minRange } = price;
      return parseFloat(`${minRange}`) <= parseFloat(`${maxPrice}`);
    default:
      return true;
  }
};
export const doesEventContainText = (event: TEvent, text: string) => {
  const { name, vendorName, keywords, details } = event;
  const searchText = text.trim().toLowerCase();
  if (searchText.length <= 0) return true;
  if (name.toLowerCase().includes(searchText)) return true;
  if (vendorName.toLowerCase().includes(searchText)) return true;
  if (keywords.toLowerCase().includes(searchText)) return true;
  if (details && details.toLowerCase().includes(searchText)) return true;
  return false;
};
export const doesVendorContainText = (vendor: TVendor, text: string) => {
  const { companyName, missionStatement, businessDescription } = vendor;
  const searchText = text.trim().toLowerCase();
  if (searchText.length <= 0) return true;
  if (companyName.toLowerCase().includes(searchText)) return true;
  if (missionStatement && missionStatement.toLowerCase().includes(searchText)) return true;
  if (businessDescription && businessDescription.toLowerCase().includes(searchText)) return true;
  return false;
};

const TYPES_MAP = {
  Events: 'Events',
  Vendors: 'Vendors,',
};

const TYPES = Object.keys(TYPES_MAP) as TypeKeys[];

type TypeKeys = keyof typeof TYPES_MAP;

export const Search = () => {
  const { user, isAuthenticated } = useAuth();

  const isAdminUser = user?.subscription === 'admin';
  const isStandardUser = user?.subscription === 'standard';

  // Hooks
  const {
    isLoading: isLoadingEvents,
    data: events,
    error: eventsError,
  } = useGetEventsQuery({ blocked: isAdminUser });
  const {
    isLoading: isLoadingVendors,
    data: vendors,
    error: vendorsError,
  } = useGetVendorsQuery({ blocked: isAdminUser });
  const { publicIsLikedEvent } = usePublicUser();
  const navigate = useNavigate();

  // Lists
  const [filteredEvents, setFilteredEvents] = React.useState<TEvent[]>([]);
  const [filteredVendors, setFilteredVendors] = React.useState<TVendor[]>([]);

  // Filters
  const [query, setQuery] = React.useState<string>('');
  const [startDate, setStartDate] = React.useState<Date | null>();
  const [maxPrice, setMaxPrice] = React.useState<number>();
  const [showLikesOnly, setShowLikesOnly] = React.useState<boolean>(false);

  // Display Type
  const [type, setType] = React.useState<TypeKeys>('Events');

  const onVendorClick = (vendor: TVendor) => {
    navigate(`/vendors/${vendor._id}`);
  };

  const filterEvents = () => {
    if (!events) return;
    const filtered = events.filter((event) => {
      // If we are public and want to see likes only
      if (showLikesOnly && !isAuthenticated && !publicIsLikedEvent(event._id)) return false;

      // If we are standard and want to see liked Events only
      // if (showLikesOnly && isStandardUser(user) && !isLikedEvent(event._id)) return false;

      // Filter by date range
      if (!isEventWithinDateRange(event, startDate)) return false;

      // Filter by Price
      if (!isEventWithinPriceRange(event, maxPrice)) return false;

      // Filter by Query
      if (query && !doesEventContainText(event, query)) return false;

      return true;
    });
    setFilteredEvents(filtered);
  };

  const filterVendors = () => {
    if (!vendors) return;
    const filtered = vendors.filter((vendor) => {
      // If we are standard and want to see liked Vendors only
      // if (showLikesOnly && isStandardUser() && !isFollowingVendor(vendor._id, user)) return false;
      // Filter by Query
      if (query && !doesVendorContainText(vendor, query)) return false;
      return true;
    });
    setFilteredVendors(filtered);
  };

  React.useEffect(() => {
    setShowLikesOnly(false);
  }, [type]);

  React.useEffect(() => {
    if (type === 'Events') filterEvents();
    if (type === 'Vendors') filterVendors();
  }, [events, vendors, query, startDate, maxPrice, showLikesOnly, type]);

  if (isLoadingEvents || !events) return <LoadingText />;
  if (isLoadingVendors || !vendors) return <LoadingText />;

  const shouldShowLikesCheckbox = !isAuthenticated || isStandardUser;
  const checkboxText = `Show liked ${type.toLowerCase()} only`;

  return (
    <Stack>
      <Container maxWidth="700px">
        <Paper elevation={2} style={{ padding: '1rem', borderRadius: '3px' }}>
          <Stack direction={'row'} alignItems={'center'} spacing={'1rem'}>
            <TextField
              placeholder={'Search'}
              value={query}
              onChange={(text) => {
                setQuery(text);
              }}
            />
            {isAuthenticated && (
              <>
                <Dropdown
                  value={type}
                  options={TYPES}
                  onChange={(v) => {
                    setType(v as TypeKeys);
                  }}
                />
                <br />
                <br />
              </>
            )}
          </Stack>
          <br />
          {shouldShowLikesCheckbox && (
            <Stack justifyContent={'center'} alignItems={'center'}>
              <FormControlLabel
                label={checkboxText}
                control={
                  <Checkbox
                    color="primary"
                    checked={showLikesOnly}
                    onChange={(value) => {
                      setShowLikesOnly(value.target.checked);
                    }}
                  />
                }
              />
            </Stack>
          )}
          <Divider flexItem />
          <br />
          {type === 'Events' && (
            <Stack spacing={'1rem'} direction={'row'}>
              <Stack flex={2} spacing={'1rem'} direction={'row'}>
                <DatePicker label="Start Date" value={startDate} onChange={setStartDate} />
              </Stack>
              <Stack
                flex={1}
                width={'100px'}
                spacing={'1rem'}
                direction={'row'}
                justifyContent={'center'}
                alignItems={'center'}
              >
                <TextField
                  type="number"
                  value={maxPrice}
                  placeholder={'Max Price'}
                  onChange={setMaxPrice}
                />
              </Stack>
            </Stack>
          )}
        </Paper>
      </Container>
      <br />
      <br />
      {type === 'Events' && (
        <Container maxWidth="800px">
          <CalendarView events={filteredEvents} />
        </Container>
      )}
      {type === 'Vendors' && (
        <Container maxWidth="800px">
          <List>
            {filteredVendors.map((vendor) => (
              <VendorItem
                key={vendor._id}
                vendor={vendor}
                onClick={onVendorClick}
                onError={console.log}
              />
            ))}
          </List>
        </Container>
      )}
      {!!eventsError && (
        <Typography color={'error'} textAlign={'center'}>
          {eventsError as string}
        </Typography>
      )}
      {!!vendorsError && (
        <Typography color={'error'} textAlign={'center'}>
          {vendorsError as string}
        </Typography>
      )}
    </Stack>
  );
};
