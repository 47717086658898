import React from 'react';
import { Stack } from '@mui/material';
import { DateTimePicker } from '../DateTimePicker';
import { NoRepeat } from './types';
import { DateTime } from '../../../shared/misc';

export type StartEnd = {
  start: Date;
  end: Date;
};

type Props = {
  value?: NoRepeat;
  onChange: (data: DateTime) => void;
};
export const StartEndPicker = ({ value, onChange }: Props) => {
  const [start, setStart] = React.useState<number>(value?.start || Date.now());
  const [end, setEnd] = React.useState<number>(value?.end || Date.now());

  React.useEffect(() => {
    if (!start || !end) {
      return;
    }
    onChange({ type: 'Does not repeat', start, end, finalDay: end });
  }, [start, end]);

  const handleStartChange = (date: Date | null) => {
    if (date) setStart(date.getTime());
  };
  const handleEndChange = (date: Date | null) => {
    if (date) setEnd(date.getTime());
  };

  return (
    <Stack spacing={'1rem'}>
      <DateTimePicker label="Event Start" value={new Date(start)} onChange={handleStartChange} />
      <DateTimePicker label="Event End" value={new Date(end)} onChange={handleEndChange} />
    </Stack>
  );
};
