import React from 'react';
import { BookmarkAddOutlinedIcon, BookmarkRemoveOutlinedIcon } from '.';
import Icon from './Icon';
import { useTheme } from '@mui/material';

export type Props = {
  isToggled?: boolean;
  onClick?: () => void;
  disabled?: boolean;
};
export const Like: React.FC<Props> = ({ isToggled, disabled, onClick }) => {
  const theme = useTheme();
  const color = isToggled ? theme.palette.warning.main : theme.palette.success.main;
  const text = isToggled ? 'Remove save for later' : 'Save for later';
  const displayColor = disabled ? 'lightgrey' : color;
  return (
    <Icon tooltip={text} onClick={onClick}>
      {!isToggled ? (
        <BookmarkAddOutlinedIcon style={{ color: displayColor }} fontSize="large" />
      ) : (
        <BookmarkRemoveOutlinedIcon style={{ color: displayColor }} fontSize="large" />
      )}
    </Icon>
  );
};
