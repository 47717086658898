import Paige_Profile from '../assets/Paige_Profile.png';
import Katie_Profile from '../assets/Katie_Profile.png';
import Brandon_Profile from '../assets/Brandon_Profile.jpg';

export const COMPANY_BIOS = [
  {
    name: 'PAIGE DENIER',
    title: 'CEO',
    image: Paige_Profile,
    email: 'paige@sociallifeofmine.com',
    contact: 'Vendor & User Support',
  },
  {
    name: 'KATIE ROSEBERRY',
    title: 'CFO',
    image: Katie_Profile,
    email: 'katie@sociallifeofmine.com',
    contact: 'Comments / Financial Support',
  },
  {
    name: 'BRANDON MENYES',
    title: 'COO',
    image: Brandon_Profile,
    email: 'brandon@sociallifeofmine.com',
    contact: 'Technical Support',
  },
];
