import { useMutation, useQueryClient } from 'react-query';
import { TSubscriptionOptions, subscribe } from '../api/users';
import { TUser } from '../auth/useAuth';

export const useSubscribeMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    (data: { user: TUser; form: TSubscriptionOptions }) => subscribe(data.user, data.form),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({ queryKey: ['getVendors'] });
        queryClient.invalidateQueries({ queryKey: ['getStandards'] });
      },
    },
  );
};
