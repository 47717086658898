import { withAuthenticationRequired } from '@auth0/auth0-react';
import { LoadingText } from '../components/Loading';
import { Navigate } from 'react-router-dom';
import React from 'react';
import { Subscription } from '../api/users';
import { TUser } from './useAuth';

type PrivateRouteProps = {
  user: TUser | undefined;
  element: React.ComponentType<object>;
  subscriptions: Subscription[];
};
export const protectBySubscription = ({
  user,
  element,
  subscriptions,
}: PrivateRouteProps): React.FC<object> => {
  if (!user || !user.subscription) return () => <Navigate to="/" replace={true} />;
  if (subscriptions.includes(user.subscription)) {
    return withAuthenticationRequired(element, { onRedirecting: () => <LoadingText /> });
  }
  return () => <Navigate to="/" replace={true} />;
};
