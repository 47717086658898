import React, { PropsWithChildren } from "react";
import { Auth0Provider } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";

export const AUTH0_CONFIG = {
    domain: 'dev-nti7uhnt.us.auth0.com',
    clientId: 'xBBeUmvuOYHOO6w1ReXNX4PFTq91DKDC',
    audience: 'https://slom-api.com',
    redirect_uri: window.location.origin,
}

export const Auth0ProviderWithHistory: React.FC<PropsWithChildren> = ({ children }) => {
    const navigate = useNavigate();

    const authorizationParams = { 
        audience: AUTH0_CONFIG.audience, 
        redirect_uri: AUTH0_CONFIG.redirect_uri,
    };

    const onRedirectCallback = (appState: any) => {
        navigate(appState?.returnTo || window.location.pathname);
    }

    return (
        <Auth0Provider
            domain={AUTH0_CONFIG.domain}
            clientId={AUTH0_CONFIG.clientId}
            authorizationParams={authorizationParams}
            onRedirectCallback={onRedirectCallback}>
            {children}
        </Auth0Provider>
    );
}