import {
  Stack,
  TextField as MUITextField,
  FormControl,
} from '@mui/material';
import { Colors } from '../../theme/colors';
import { Text } from '../text';

const inputStyle = {
  border: `1px solid ${Colors.primary}`,
  borderRadius: '7px',
  padding: '10px',
  color: 'black',
};

type Props = {
  // Mandatory
  onChange: (text: any) => void;

  // Optional
  startAdornment?: any;
  label?: string;
  description?: string;
  value?: string | number;
  error?: string;
  required?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  placeholder?: string;
  type?: 'text' | 'password' | 'number';
  tabIndex?: number;
  style?: any;
  onBlur?: () => void;
  onKeyDown?: (e: any) => void;
};
const TextField = ({
  value,
  label,
  description,
  error,
  onChange,
  onBlur,
  onKeyDown,
  required,
  disabled,
  type = 'text',
  multiline,
  startAdornment,
  placeholder,
  tabIndex,
  style,
}: Props) => {
  const star = required ? '*' : '';
  const optional = required ? '' : '(Optional)';
  const labelText = `${star}${label} ${optional}`;

  return (
    <Stack style={{ width: '100%' }}>
      {label && <Text disabled={disabled || false} style={{ ...style }}>{labelText}</Text>}
      {description && <Text disabled={disabled || false} style={{ ...style }}>{`(${description})`}</Text>}
      <FormControl>
        <MUITextField
          tabIndex={tabIndex}
          placeholder={placeholder}
          multiline={multiline || false}
          value={value}
          variant="standard"
          autoComplete="false"
          autoSave="false"
          autoCorrect="false"
          disabled={disabled || false}
          required={required || false}
          type={type}
          fullWidth
          error={error !== undefined}
          helperText={error}
          onChange={(e) => onChange(e.target.value)}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          style={{ border: 0 }}
          inputProps={{
            style: {
              ...inputStyle,
              color: disabled ? 'lightgrey' : undefined,
              borderColor: disabled ? 'lightgrey' : Colors.primary,
              height: multiline ? '110px' : undefined,
            },
            autoComplete: 'new-password',
            form: { autoComplete: 'off' },
          }}
          InputProps={{
            startAdornment,
            disableUnderline: true,
          }}
        />
      </FormControl>
    </Stack>
  );
};

export default TextField;
