import React from 'react';
import { EmailOutlinedIcon } from '.';
import Icon from './Icon';
import { useTheme } from '@mui/material';

export type Props = {
  onClick: () => void;
};
export const Message: React.FC<Props> = ({ onClick }) => {
  const theme = useTheme();
  const color = theme.palette.secondary.main;
  return (
    <Icon tooltip="Messages" onClick={onClick}>
      <EmailOutlinedIcon style={{ color }} fontSize="large" />
    </Icon>
  );
};
